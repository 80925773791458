import { z } from 'zod';

// Define the Signup schema
const SignupSchema = z.object({
  name: z.string()
  .min(1, { message: "Name must be at least 1 character long" }) // Ensure minimum length of 1
  .refine((val) => isNaN(Number(val)), { message: "Name should not be a number" }),// Ensure name is not a number

  email: z.string().email("Invalid email format"), // Must be a valid email
  password: z.string().min(6, "Password must be at least 6 characters long"), // Password length > 5
  confirm_password: z.string().min(6, "Confirm Password must be at least 6 characters long") // Same validation as password
}).refine(data => data.password === data.confirm_password, {
  message: "Passwords don't match",
  path: ['confirm_password'], // Show error on confirm_password field
});

export default SignupSchema;
