import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db, storage } from "../../utils/firebase";
import { deleteObject, listAll, ref } from "firebase/storage";

import { BiMenuAltLeft } from "react-icons/bi";
import { FaRegEdit } from "react-icons/fa";
import { IoPersonCircleOutline } from "react-icons/io5";
import { Oval } from "react-loader-spinner";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbLayoutSidebar } from "react-icons/tb";
import moment from "moment/moment";
import toast from "react-hot-toast";
import { truncateString } from "../../utils/helper";
import useSiderbar from "../../hooks/useSidebar";
import useUser from "../../hooks/useUser";

function categorizeObjectsByDate(objects) {
  const categorized = {
    Today: [],
    Yesterday: [],
    "Past 30 Days": [],
  };

  const currentDate = new Date();
  const todayStart = new Date(currentDate.setHours(0, 0, 0, 0));
  const yesterdayStart = new Date(currentDate.setHours(-24, 0, 0, 0));
  const past30DaysStart = new Date(
    currentDate.setDate(currentDate.getDate() - 30)
  );

  objects.forEach((obj) => {
    const objDate = obj.createdAt.toDate();

    if (objDate >= todayStart) {
      categorized["Today"].push(obj);
    } else if (objDate >= yesterdayStart && objDate < todayStart) {
      categorized["Yesterday"].push(obj);
    } else if (objDate >= past30DaysStart && objDate < yesterdayStart) {
      categorized["Past 30 Days"].push(obj);
    } else {
      const monthName = objDate.toLocaleString("default", { month: "long" });
      const year = objDate.getFullYear();
      const monthYear = `${monthName} ${year}`;

      if (!categorized[monthYear]) {
        categorized[monthYear] = [];
      }
      categorized[monthYear].push(obj);
    }
  });

  // Convert the categorized object to an array of objects with title and conversations keys
  const categorizedArray = Object.entries(categorized).map(
    ([title, conversations]) => ({
      title,
      conversations,
    })
  );

  return categorizedArray;
}


const DeleteComponent=({id})=>{

  const [deleting, setDeleting] = useState(false);

  async function deleteConversation(conversationId) {
    try {
      setDeleting(true);
  
      // 1. Delete folder and its contents from Firebase Storage
      const folderRef = ref(storage, `messages/${conversationId}`);
      const listResponse = await listAll(folderRef);
  
      const deleteFilePromises = listResponse.items.map((itemRef) =>
        deleteObject(itemRef)
      );
  
      await Promise.all(deleteFilePromises);
      console.log("All files in the folder deleted successfully.");
  
      // 2. Delete all messages with the given conversation ID
      const messagesRef = collection(db, "messages");
      const q = query(messagesRef, where("conversation", "==", conversationId));
  
      const querySnapshot = await getDocs(q);
  
      const deletePromises = querySnapshot.docs.map((document) =>
        deleteDoc(doc(db, "messages", document.id))
      );
  
      await Promise.all(deletePromises);
      console.log("All related messages deleted successfully.");
  
      // 3. Delete the conversation document itself
      const conversationDocRef = doc(db, "conversations", conversationId);
      await deleteDoc(conversationDocRef);
      console.log("Conversation document deleted successfully.");
  
      setDeleting(false);
      toast.success("Deleted Successfully");
    } catch (error) {
      console.error("Error deleting conversation data:", error);
      toast.error("Something went wrong");
      setDeleting(false);
    }
  }

  return <>{!deleting?<RiDeleteBin6Line
  onClick={(e) => {
    e.stopPropagation();
    deleteConversation(id);
  }}
  className="min-w-[32px] cursor-pointer ml-auto text-xl text-slate_gray"
/>:
<Oval
visible={true}
height="25"
width="25"
color="#706aee"
secondaryColor="#b3b5db"
ariaLabel="oval-loading"
/>}
</>
}

const History = () => {
  const { user } = useUser();
  const [conversations, setConversations] = useState(null);
  const { isSidebarOpen, setIsSidebarOpen } = useSiderbar();
 
  const navigate=useNavigate()

  
  

  useEffect(() => {
    const q = query(
      collection(db, "conversations"),
      where("user", "==", user?.id),
      orderBy("createdAt", "desc")
    );
    const unsub = onSnapshot(q, (querySnapshot) => {
      let conversations_temp = [];
      querySnapshot.forEach((doc) => {
        conversations_temp.push({ id: doc?.id, ...doc.data() });
      });

      console.log("Conversations--->", conversations_temp);
      conversations_temp = categorizeObjectsByDate(conversations_temp);
      console.log("--->>>>>Temo",conversations_temp)
      conversations_temp=conversations_temp.every(item => item.conversations.length === 0)?[]:conversations_temp
      console.log("--->>>>>Temo",conversations_temp)
      setConversations(conversations_temp);
    });
    return unsub;
  }, []);
  return (
    <div className="w-full h-full  bg-white rounded-lg overflow-hidden flex flex-row relative">
      {/* {deleting && (
        <div className="w-full h-full absolute top-0 right-0 bg-black bg-opacity-30 flex items-center justify-center">
          <Oval
            visible={true}
            height="30"
            width="30"
            color="#fff"
            secondaryColor="#b3b5db"
            ariaLabel="oval-loading"
          />
        </div>
      )} */}

      <div className="flex-grow h-full  flex flex-col p-2">
        <div className="w-full  h-[5%] min-h-[32px] flex flex-row items-center justify-between  ">
          <BiMenuAltLeft
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="text-3xl cursor-pointer    block"
          />

          <div className="flex flex-row justify-center items-center gap-2">
            <h1 className=" ">Past History</h1>
            <FaRegEdit className="" />
          </div>
          <Link to='/profile' className="flex flex-row items-center justify-between gap-2">
            <IoPersonCircleOutline
              className={`text-3xl text-slate_gray  duration-300 cursor-pointer `}
            />
          </Link>
        </div>

        <div className="h-[95%] flex-grow  overflow-y-scroll no-scrollbar px-4">
          {!conversations?<div className="text-center flex items-center justify-center mt-10">
            <Oval
              visible={true}
              height="60"
              width="60"
              color="#706aee"
              secondaryColor="#b3b5db"
              ariaLabel="oval-loading"
            />
          </div>:conversations.length > 0
            ? conversations.map((conversation, index) => {
                return conversation?.conversations?.length > 0 ? (
                  <div key={index} className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-row items-center gap-2 mt-8 mb-2">
                        <h1 className="font-light">{conversation?.title}</h1>
                      </div>

                      {conversation.conversations.map((conv) => {
                        return (
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/?conversation=${conv?.id}`);
                            }}
                            className="cursor-pointer group hover:border-slate-500 duration-300 flex-row justify-between w-full px-2 py-3 border-2 rounded-lg border-grey  flex items-center "
                          >
                            <h1 className="text-slate_gray group-hover:border-slate-500">
                              {truncateString(conv?.title, 100)}
                            </h1>
                            <div className="flex flex-row items-center gap-5">
                              <h1 className="text-gray-400 group-hover:text-gray-600 duration-300 text-sm">{moment(new Date(conv?.createdAt.seconds * 1000 + conv?.createdAt.nanoseconds / 1000000)).format("DD MMM, YYYY")}</h1>
                            <DeleteComponent key={conv?.id} id={conv?.id}/>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null;
              })
            : <h1 className="mt-[100px] text-slate_gray font-bold text-2xl text-center ">No History</h1>}
        </div>
      </div>
    </div>
  );
};

export default History;
