import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { auth, db } from "../../utils/firebase";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";

import Button from "../../components/Button";
import Input from "../../components/Input";
import { Link } from "react-router-dom";
import SignupSchema from "../../utils/formSchema/signup";
import {
  createUserWithEmailAndPassword,
} from "firebase/auth";
import toast from "react-hot-toast";
import useAuthAction from "../../hooks/useAuthAction";
import { useForm } from "react-hook-form";
import { useState } from "react";
import {zodResolver} from "@hookform/resolvers/zod"

function Signup() {
  
  const [showPassword, setShowPassword] = useState(false);
  const {setAuthAction}=useAuthAction()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(
    {
      resolver:zodResolver(SignupSchema)
    }

  );



  const [isLoading, setIsLoading] = useState(false);
 

 

  const onSubmit = async (data) => {
    try {

      if(data?.password!==data?.confirm_password)
        {
          toast.error("Confirm your password")
          return
        }

      setIsLoading(true);
      const user = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
     let dataTemp = {
        name: `${data?.name}`,
        email: data.email,
        createdAt: serverTimestamp(),
        lastLoggedInAt: serverTimestamp()
      };
      await setDoc(doc(db, "users", user?.user?.uid), dataTemp);
      reset();  
      setAuthAction('signup')
      toast.success("Account Created Successfully");

      // navigate("/login");
      // toast.success("Verification Email sent");
    } catch (error) {
      console.log(error);

      if (error.code === "auth/email-already-in-use") {
        toast.error("Email Already Exists");
      } else {
        toast.error("Something Went Wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="grid grid-cols-6 w-screen h-screen flex-row">
  <div className="h-full  items-center justify-center px-5 col-span-2 bg-gradient-to-b from-light_wenge to-steel_blue hidden md:flex">
<img src='/assets/aichat.svg' className="object-cover mx-5"/>
      </div>
    {/* bg-white */}
    <div className=" h-full col-span-6 md:col-span-4 overflow-y-scroll no-scrollbar  bg-gradient-to-b from-light_wenge md:from-white to-steel_blue md:to-white ">
      <div
        className="
        w-[90%]
        md:w-[50%]
          
          mt-[5%]
          mx-auto
          md:mx-2
          md:ml-[10%]
          
          flex
          flex-col 
          items-center 
          "
      >
        <div className=" flex flex-col gap-1  w-full mt-6 md:mt-0 mb-4 md:mb-16  text-center md:text-left">
          <h1 className="text-3xl md:text-4xl font-bold ">Welcome to Alltrials.ai</h1>
          <p className="text-slate_gray font-light">
          Create your account by entering your name, email and password.
          </p>
        </div>

        <form className="gap-4 flex flex-col w-full my-2 ">

        <div className="flex flex-col gap-1">
            <h1 className="ml-1 tracking-wider">Name</h1>

            <Input
             
              id="name"
              label={"Name"}
              placeholder={"Enter your name"}
              required
              register={register}
              errors={errors}
            />
          </div>
          <div className="flex flex-col gap-1">
            <h1 className="ml-1 tracking-wider">Email</h1>

            <Input
             
              id="email"
              label={"Email"}
              placeholder={"Enter your email"}
              required
              register={register}
              errors={errors}
            />
          </div>

          <div className="flex flex-col gap-1">
            <h1 className="ml-1 tracking-wider ">Password</h1>
            <Input
              id="password"
              label={"Password"}
              type={!showPassword && "password"}
              placeholder={"Enter your password"}
              required
              register={register}
              errors={errors}
              RightIcon={() => (
                <div
                  onClick={() => setShowPassword(!showPassword)}
                  className="cursor-pointer"
                >
                  {showPassword ? (
                    <IoMdEye className="text-lg" />
                  ) : (
                    <IoMdEyeOff className="text-lg" />
                  )}
                </div>
              )}
            />
          </div>

          <div className="flex flex-col gap-1">
            <h1 className="ml-1 tracking-wider ">Password</h1>
            <Input
              id="confirm_password"
              label={"Confirm Password"}
              type={!showPassword && "password"}
              placeholder={"Confirm your password"}
              required
              register={register}
              errors={errors}
              RightIcon={() => (
                <div
                  onClick={() => setShowPassword(!showPassword)}
                  className="cursor-pointer"
                >
                  {showPassword ? (
                    <IoMdEye className="text-lg" />
                  ) : (
                    <IoMdEyeOff className="text-lg" />
                  )}
                </div>
              )}
            />
          </div>
          <div className="w-[250px] mt-2 md:mt-8 self-center md:self-start">
            <Button
              disabled={isLoading}
              label="Sign Up"
              onClick={handleSubmit(onSubmit)}
            />
          </div>

          <div className="flex flex-row  items-center">
            <p className="text-offWhite">
              Already have an account?
            </p>
            <Link
              to="/login"
              className="text-slate_blue text-xl underline cursor-pointer ml-1"
            >
             Login
             
            </Link>
          </div>

          
        </form>
      </div>
    </div>
   
  </div>
  );
}

export default Signup;
