import "../../../styles/agent.scss";
import Modal from "../../../components/modal/modal";
import useAgentModal from "../../../hooks/modal/useAgentModal";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";

const Agent = () => {
  const agentModal = useAgentModal();
  const width = 1000;
  const height = 300;
  const nodeRadius = 30;
  const tasks = [
    { priority: 1, description: "Completed FDA submission for Product X" },
    {
      priority: 2,
      description: "Reviewed statistical analysis plan for Study Y",
    },
    { priority: 3, description: "Drafted response to FDA queries" },
    {
      priority: 4,
      description: "Conducted QC review of clinical study report",
    },
    { priority: 5, description: "Updated regulatory strategy document" },
  ];

  const nodes = [
    { id: "regulatory", label: "Regulatory", x: width * 0.2, y: height * 0.5 },
    { id: "writer", label: "Writer", x: width * 0.4, y: height * 0.3 },
    { id: "review", label: "Review", x: width * 0.6, y: height * 0.3 },
    { id: "qc", label: "QC", x: width * 0.8, y: height * 0.5 },
    { id: "statplan", label: "Stat Plan", x: width * 0.4, y: height * 0.7 },
    {
      id: "fdarespose",
      label: "FDA Response",
      x: width * 0.6,
      y: height * 0.7,
    },
  ];
  const edges = [
    { source: "regulatory", target: "writer" },
    { source: "writer", target: "review" },
    { source: "review", target: "qc" },
    { source: "regulatory", target: "statplan" },
    { source: "statplan", target: "review" },
    { source: "qc", target: "fdarespose" },
    { source: "regulatory", target: "fdarespose" },
  ];

  const agentModalBodyContent = (
    <div className="modal-content">
      <IoClose onClick={() => agentModal.onClose()} className="close" />
      <h2>{agentModal.title}</h2>
      {agentModal.title === "Agent Performance Review" ? (
        <div id="performanceContent">
          <h3>Agent Performance Metrics</h3>
          <ul className="list-disc">
            <li>Regulatory Agent: 95%</li>
            <li>Writer Agent: 88%</li>
            <li>Review Agent: 92%</li>
            <li>QC Agent: 97%</li>
            <li>Statistical Plan Agent: 90%</li>
            <li>FDA Response Agent: 93%</li>
          </ul>
        </div>
      ) : agentModal.title === "Agent Career Development" ? (
        <div id="careerContent">
          <h3>Career Development Options</h3>
          <ul className="list-disc">
            <li>Advanced Regulatory Affairs Training</li>
            <li>Leadership Skills Workshop</li>
            <li>AI and Machine Learning in Regulatory Science</li>
            <li>Communication Skills Enhancement</li>
          </ul>
        </div>
      ) : (
        <div className="agent-info">
          <h3>Recent Work History</h3>
          <div id="regulatoryWorkHistory"></div>
          <h3>Chat with Human History</h3>
          <div id="regulatoryChatHistory"></div>
          <h3>Planned Tasks</h3>
          <ul id="regulatoryPlannedTasks"></ul>
        </div>
      )}
    </div>
  );

  return (
    <div className="agent">
      <header>
        <div className="container">
          <h1>Papaya</h1>
          <p>Unleash your creativity with our powerful AI-powered tools</p>
        </div>
      </header>

      <main className="container">
        <div className="tool-grid">
          <div className="tool-card">
            <div className="tool-header" id="regulatoryAgent">
              <h2 className="tool-title">Regulatory Affairs Agent</h2>
              <p className="tool-description">
                Manage regulatory compliance and submissions
              </p>
              <a
                className="tool-button cursor-pointer"
                onClick={() => agentModal.onOpen("Regulatory Affairs Agent")}
              >
                View Agent
              </a>
            </div>
          </div>
          <div className="tool-card">
            <div className="tool-header" id="writerAgent">
              <h2 className="tool-title">Writer Agent</h2>
              <p className="tool-description">
                Create and edit regulatory documents
              </p>
              <Link 
                to="/word-ai-editor"
                className="tool-button"
              >
                View Agent
              </Link>
            </div>
          </div>
          <div className="tool-card">
            <div className="tool-header" id="reviewAgent">
              <h2 className="tool-title">Review Agent</h2>
              <p className="tool-description">Conduct thorough document reviews</p>
              <a
                className="tool-button cursor-pointer"
                onClick={() => agentModal.onOpen("Review Agent")}
              >
                View Agent
              </a>
            </div>
          </div>
          <div className="tool-card">
            <div className="tool-header" id="qcAgent">
              <h2 className="tool-title">QC Agent</h2>
              <p className="tool-description">
                Ensure quality control in all processes
              </p>
              <a
                className="tool-button cursor-pointer"
                onClick={() => agentModal.onOpen("QC Agent")}
              >
                View Agent
              </a>
            </div>
          </div>
          <div className="tool-card">
            <div className="tool-header" id="statPlanAgent">
              <h2 className="tool-title">Statistical Plan Agent</h2>
              <p className="tool-description">
                Develop and review statistical plans
              </p>
              <a
                className="tool-button cursor-pointer"
                onClick={() => agentModal.onOpen("Statistical Plan Agent")}
              >
                View Agent
              </a>
            </div>
          </div>
          <div className="tool-card">
            <div className="tool-header" id="fdaResponseAgent">
              <h2 className="tool-title">FDA Response Agent</h2>
              <p className="tool-description">
                Handle FDA communications and responses
              </p>
              <a
                className="tool-button cursor-pointer"
                onClick={() => agentModal.onOpen("FDA Response Agent")}
              >
                View Agent
              </a>
            </div>
          </div>
        </div>
        <div className="additional-pages">
          <a href="https://creativeai.tools/daily-standup" className="page-button">
            Daily Stand-up
          </a>
          <a
            className="page-button cursor-pointer"
            onClick={() => agentModal.onOpen("Agent Performance Review")}
          >
            Agent Performance Review
          </a>
          <a
            className="page-button cursor-pointer"
            onClick={() => agentModal.onOpen("Agent Career Development")}
          >
            Agent Career Development
          </a>
        </div>
      </main>

      <div className="workflow-container">
        <div className="reorg-button-container">
          <a
            href="https://creativeai.tools/workflow-ab-test"
            className="page-button"
          >
            Reorganize Workflow
          </a>
        </div>
        <section className="workflow-visualization">
          <h2>Agent Workflow Visualization</h2>
          <svg id="workflowSvg" width="100%" height="300">
            {edges.map((edge) => {
              const source = nodes.find((n) => n.id === edge.source);
              const target = nodes.find((n) => n.id === edge.target);
              return (
                <line
                  className="edge"
                  x1={source.x}
                  y1={source.y}
                  x2={target.x}
                  y2={target.y}
                />
              );
            })}
            {nodes.map((node) => (
              <>
                <circle className="node" cx={node.x} cy={node.y} r={nodeRadius} />
                <text className="node-text" x={node.x} y={node.y}>
                  {node.label}
                </text>
              </>
            ))}
          </svg>
        </section>
        <section className="recent-tasks">
          <h2>Recent Finished Tasks</h2>
          <ul id="taskList" className="task-list">
            {tasks
              .sort((a, b) => a.priority - b.priority)
              .map((task) => (
                <li>
                  <span className="task-priority">{task.priority}</span>
                  {task.description}
                </li>
              ))}
          </ul>
        </section>
      </div>
      <Modal
        isOpen={agentModal.isOpen}
        onClose={agentModal.onClose}
        body={agentModalBodyContent}
      />
    </div>
  );
};

export default Agent;
