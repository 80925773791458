import "react-datepicker/dist/react-datepicker.css";

import Input from "../../Input";
import Modal from "../modal";
import { auth } from "../../../utils/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import useResetPasswordModal from "../../../hooks/modal/useResetPasswordModal";
import { useState } from "react";

function ResetPasswordModal() {
  const resetPasswordModal = useResetPasswordModal()
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email:""
    },
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
        
        sendPasswordResetEmail(auth, data.email)
      toast.success("Email sent");
      reset();

      resetPasswordModal.onClose();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      console.log(error.code);
      console.log(error.message);

     
     toast.error("Something Went Wrong");
      
    }
  };

  const bodyContent = (
    <div className="flex flex-col gap-4">
      <Input
        id="email"
        label="Password"
        type="text"
        errors={errors}
        register={register}
        required={true}
        placeholder="Email"
      />
    </div>
  );

  return (
    <Modal
      isOpen={resetPasswordModal.isOpen}
      onClose={resetPasswordModal.onClose}
      onSubmit={handleSubmit(onSubmit)}
      title={"Password Reset"}
      actionLabel="Send"
      disabled={isLoading}
      body={bodyContent}
    />
  );
}

export default ResetPasswordModal

