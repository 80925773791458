import { create } from "zustand";

const useAgentModal = create((set) => ({
  isOpen: false,
  title: null,
  onOpen: (title) => set((state) => ({ title, isOpen: true })),
  onClose: () => set((state) => ({ title: null, isOpen: false })),
}));

export default useAgentModal;
