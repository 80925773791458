import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db, storage } from "../../../utils/firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useCallback, useState } from "react";

import { IoClose } from "react-icons/io5";
import { IoCloudUploadOutline } from "react-icons/io5";
import Modal from "../modal";
import { Oval } from "react-loader-spinner";
import { SlCloudUpload } from "react-icons/sl";
import axios from "axios";
import toast from "react-hot-toast";
import { useDropzone } from "react-dropzone";
import useFiles from "../../../hooks/useFiles";
import useUploadDocument from "../../../hooks/modal/useUploadDocumentModal";
import useUser from "../../../hooks/useUser";
import { v4 as uuidv4 } from "uuid";

function UploadDocument() {
  const uploadDocument = useUploadDocument();
  const [loading, setLoading] = useState(false);
  const { user } = useUser();
  const {files}=useFiles()

  const onDrop = useCallback(async (acceptedFiles) => {
    console.log(acceptedFiles);
    console.log(files.length)
    console.log(files.length*1+acceptedFiles?.length*1)

    if(files.length*1+acceptedFiles?.length*1 >5)
      {
        toast.error("Maximum 5 files can be uploaded")
        return 
      }
      
    if (acceptedFiles?.length > 0) {
      try {
        
        setLoading(true);

        const uploadPromises = acceptedFiles.map(async (file) => {
          const file_name = uuidv4();
          console.log(file_name)
          const title = file?.name;
          console.log(`files/${file_name}`)
          const coverRef = ref(storage, `files/${file_name}`);
         

          await uploadBytes(coverRef, file); // Upload the file
          const file_url = await getDownloadURL(coverRef); // Get the file URL

          const data = {
            title: title,
            file_name: file_name,
            file_url: file_url,
            user: user?.id,
            size:file?.size,
            type:file?.type,
            createdAt: serverTimestamp(),
          };
          const result=await addDoc(collection(db, "files"), data);

          return { url:file_url, doc_id:result.id }; // Return the file_url and file_name as an object
        });

        // Wait for all uploads to complete
        const uploadedFiles = await Promise.all(uploadPromises);
        const formData={user_id: user?.id, doc_id_urls:uploadedFiles}
        console.log("---?????",formData);
        // Update state with the uploaded files

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/create_embeddings`, formData)
      .then((response) => {
        console.log('Success:', response.data);
        toast.success("Trained Successfully!");
        setLoading(false);
        
        uploadDocument.onClose()
        // Handle success actions here
      })
      .catch((error) => {
        console.error('Error:', error);
        toast.error(error?.response?.data?.message)
        setLoading(false);
        
        uploadDocument.onClose()
        // Handle error actions here
      });

        
      } catch (error) {
        console.log(error);
        setLoading(false);
        toast.error("Something went wrong");
      }
    }
  }, [files]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    multiple: true,
    onDrop,
  });

  const bodyContent = (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row items-center justify-between">
        <h1>Upload File</h1>
        <IoClose
          onClick={() => uploadDocument.onClose()}
          className="text-slate_blue text-xl cursor-pointer"
        />
      </div>
      <h1 className="text-slate_gray font-light">
        Choose at most <span className="text-slate_blue">5 files</span> for
        uploading
      </h1>

      <div
        className="w-full py-10 shrink-0 flex items-center justify-center rounded-xl border-slate_blue border-dashed border-[1px] cursor-pointer"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {loading ? (
          <div className="h-[180px] flex flex-col gap-4 justify-center items-center">
            <Oval
              visible={true}
              height="60"
              width="60"
              color="#706aee"
              secondaryColor="#b3b5db"
              ariaLabel="oval-loading"
            />
            <h1 className="text-lg font-semibold text-gray-400">AI is training on the documents, please wait...</h1>
          </div>
        ) : (
          <div className="flex flex-col gap-1 items-center  h-[180px] overflow-hidden ">
            <SlCloudUpload className="text-slate_gray text-[90px]" />
            <h1 className="font-semibold tracking-wide mt-4">
              Drag & Drop to Upload
            </h1>
            <h1 className="underline font-light text-slate_blue tracking-wide">
              or browse
            </h1>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <Modal
      isOpen={uploadDocument.isOpen}
      onClose={uploadDocument.onClose}
      disabled={loading}
      body={bodyContent}
    />
  );
}

export default UploadDocument;
