// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import Button from "../../components/Button";
// import required modules
import { Controller } from "swiper/modules";
import { FaArrowLeft } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa";
import { GoArrowRight } from "react-icons/go";
import { HiOutlineMail } from "react-icons/hi";
import { IoLocationOutline } from "react-icons/io5";
import { LuPhoneCall } from "react-icons/lu";
import { MdEmail } from "react-icons/md";
import { auth } from "../../utils/firebase";
import { signOut } from "firebase/auth";

const Facility1 = ({ title, description, image = null, video = null }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 rounded-lg overflow-hidden bg-white">
      <div className=" flex flex-col items-center  px-4 py-8">
        <h1 className="text-left w-full text-2xl font-bold mb-3">{title}</h1>
        <p className="text-left w-full text-slate_gray font-light">
          {description}
        </p>
      </div>
      <div className="">
        {image ? (
          <img
            src={image}
            className="w-full rounded-xl sm:rounded-none overflow-hidden sm:w-auto h-auto sm:max-h-[400px]  sm:ml-auto"
          />
        ) : (
          <video
            src={video}
            controls
            className="w-full rounded-xl sm:rounded-none overflow-hidden sm:w-auto h-auto sm:max-h-[400px]  sm:ml-auto"
          ></video>
        )}
      </div>
    </div>
  );
};

const Facility2 = ({ title, description, image }) => {
  return (
    <div className="flex flex-col p-3 bg-white rounded-lg">
      <h1 className="text-left w-full text-2xl font-bold mb-3">{title}</h1>
      <div class="relative w-full ">
        <p className="text-left w-full text-slate_gray font-light">
          {description}
        </p>

        <div class="float-right w-20 h-20  relative">
          <img
            src={image}
            className="absolute right-0 bottom-0 min-w-[140px]"
          />
        </div>
      </div>
    </div>
  );
};

const Landing = () => {
  const [email, setEmail] = useState("");
  const [swiper1, setSwiper1] = useState(null);
  const [swiper2, setSwiper2] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const videoRef = useRef(null);
  const navigate = useNavigate();

  const benefits = [
    {
      image: "/assets/ai.svg",
      title: "Predictive IND submission planning",
      description:
        "AI-driven prediction of health authority inquiries for Pre-IND to IND submission that allow organizations to develop robust scenario-tested study plan and build comprehensive action plans well in advance.",
      link: "#",
    },
    {
      image: "/assets/document.svg",
      title: "Faster response times",
      description:
        "Our customized AI trained on past query history and FDA guidelines responds promptly, thoroughly, and strategically to the Health Authority",
      link: "#",
    },
    {
      image: "/assets/bot.svg",
      title: "Regulatory Strategy Simulations",
      description:
        "Develop a well-defined Regulatory strategy that is aligned with your product’s development and commercial goals. Consider regional requirements, product classification, and potential challenges to devise an optimal submission plan.",
      link: "#",
    },
    {
      image: "/assets/shield.svg",
      title: "Clinical-study reports Drafting",
      description:
        "As the final step before regulatory review, submission writing must be done as quickly and accurately as possible to achieve or accelerate launch time lines. Yet drafting the clinical-study reports typically requires eight or more weeks to complete. Gen AI–based tools can cut this time almost in half by generating an “80 percent right” first draft from the underlying protocol, statistical-analysis plan, and tables, listings, and figures—within minutes instead of days—enabling rapid, collaborative iteration by cross-functional stakeholders through conversational prompting.",
      link: "#",
    },
    {
      image: "/assets/history.svg",
      title: "AI-based trial design and excution",
      description:
        "AI can analyze historical trial data and real-world evidence to help design protocols that maximize efficacy while minimizing risks and costs. This includes selecting the optimal dosage, treatment duration, and patient population.",
      link: "#",
    },
    {
      image: "/assets/setting.svg",
      title: "Real-World Evidence Integration",
      description:
        "By integrating real-world evidence, AI can support decision-making throughout the trial process, from design to analysis, ensuring that the trial outcomes are relevant and applicable to real-world settings.",
      link: "#",
    },
  ];

  const reviews = [
    {
      name: "Anna Julie",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-K5Wy_yD_JlW5V6d6ApSHnn9W7G48kMcMug&s",
      rating: 5,
      text: "Lorem ipsum dolor sit amet consectetur. Etiam non arcu imperdiet pulvinar donec quis mattis sagittis lacus. Nullam suscipit quisque ullamcorper ac at gravida amet volutpat vitae. Iaculis morbi diam sagittis arcu luctus tristique consectetur. Sodales in arcu tempor porttitor leo ornare congue ut.",
    },
    {
      name: "John Doe",
      image:
        "https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=800",
      rating: 4,
      text: "Lorem ipsum dolor sit amet consectetur. Etiam non arcu imperdiet pulvinar donec quis mattis sagittis lacus. Nullam suscipit quisque ullamcorper ac at gravida amet volutpat vitae. Iaculis morbi diam sagittis arcu luctus tristique consectetur. Sodales in arcu tempor porttitor leo ornare congue ut.",
    },
    {
      name: "William",
      image:
        "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?auto=compress&cs=tinysrgb&w=800",
      rating: 5,
      text: "Lorem ipsum dolor sit amet consectetur. Etiam non arcu imperdiet pulvinar donec quis mattis sagittis lacus. Nullam suscipit quisque ullamcorper ac at gravida amet volutpat vitae. Iaculis morbi diam sagittis arcu luctus tristique consectetur. Sodales in arcu tempor porttitor leo ornare congue ut.",
    },
  ];

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  }, []);

  useEffect(() => {
    const video = videoRef.current;

    // Callback for when intersection changes
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          video.play(); // Play video when in view
        } else {
          video.pause(); // Pause video when out of view
        }
      });
    };

    // Create the intersection observer
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5, // 50% of the video must be in view to trigger play
    });

    if (video) {
      observer.observe(video); // Start observing the video element
    }

    return () => {
      // Clean up the observer when the component unmounts
      if (video) {
        observer.unobserve(video);
      }
    };
  }, []);

  return (
    <div className="flex flex-col">
      {/* Hero */}
      <div
        id="home"
        className="relative flex items-center justify-center min-h-[500px] "
      >
        {/* Colored circles */}
        <div className="absolute w-48 h-48 bg-slate_blue rounded-full left-20 top-20 opacity-70"></div>
        <div className="absolute w-48 h-48 bg-orange rounded-full right-20 bottom-20 opacity-70"></div>

        {/* Glassmorphism box */}
        <div className="relative  w-full flex flex-col  min-h-[500px] mx-auto bg-white/50   backdrop-blur-2xl ">
          <div className="w-full flex flex-col sm:flex-row items-center justify-between min-h-[100px] p-4 mx-auto max-w-[1440px]">
            <img className="w-36 hidden sm:block" src="/assets/logo.svg" />
            <div className="flex flex-row items-center gap-7 ">
              <a
                href="#home"
                className="font-light decoration-none text-[16px]"
              >
                Home
              </a>
              <a
                href="#about"
                className="font-light decoration-none text-[16px]"
              >
                About Us
              </a>
              <a
                href="#contact"
                className="font-light decoration-none text-[16px]"
              >
                Contact Us
              </a>
            </div>

            <div className="flex flex-row items-center gap-3 min-w-[230px]">
              <Button
                onClick={() => navigate("/login")}
                small
                outline
                label="Sign In"
              />

              <Button
                onClick={() => navigate("/signup")}
                small
                label="Free Trial"
              />
            </div>
          </div>
          {/* Hero */}

          <h1 className="mt-20 text-2xl md:text-4xl font-bold tracking-[3px] leading-6 text-center w-auto  lg:w-[800px] mx-4 md:mx-auto">
            Accelerate Clinical Operation and Regulatory{" "}
            <span className="text-slate_blue">
              Approval Process enabled by AI-driven
            </span>{" "}
            insights
          </h1>

          <p className="mt-6 text-gray-400 font-light text-center w-auto  md:w-[600px] mx-4 md:mx-auto">
            Streamline your regulatory submission process, minimize risks, and
            accelerate time-to-market with our AI-enhanced regulatory
            intelligence and medical writer.
          </p>

          <div className="mt-20 px-4 py-2 bg-white shadow-xl flex flex-row gap-2 items-center rounded-full w-auto  md:w-[600px] mx-4 md:mx-auto">
            <MdEmail className="text-2xl" />
            <input
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="flex-grow outline-none "
            />
            <div className="min-w-[130px] min-h-[40px] bg-slate_blue text-white flex items-center justify-center rounded-full">
              Get In Touch
            </div>
          </div>

          <div className="w-full  mt-10   flex flex-col justify-center p-4 bg-gradient-to-b from-white to-steel_blue">
            <div className="max-w-[1440px] w-full flex-grow mx-auto overflow-x-scroll sm:overflow-hidden  flex flex-row justify-between gap-10 items-center ">
              <img
                src="https://www.mdanderson.org/etc.clientlibs/mda/clientlibs/mda-web/clientlib-site/resources/images/xmda-logo.png.pagespeed.ic.ciGH9dxNVR.webp"
                className="object-cover max-w-[120px]"
              />
              <img
                src="https://www.novartis.com/themes/custom/polaris/logo.svg"
                className="object-cover max-w-[160px]"
              />
              <img
                src="https://www.uth.edu/global/gfx/uthealth-houston-main.svg"
                className="object-cover max-w-[150px]"
              />
              <img
                src="https://www.amgen.com/-/media/Themes/Global/Global/Global/images/migration/Common/amgen-blue.svg?la=en&hash=2F9332F2F46EA1E1EF9CCF9D40B8C7B8"
                className="object-cover max-w-[180px]"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col mx-auto gap-8 max-w-[1440px] mt-4">
        <h1 className="text-center text-[16px] font-light tracking-wider mb-10 md:mb-4">
          We’re a team of pioneers and scholars from leading global medical
          institutions.
        </h1>
        {/* {About us} */}
        <div
          id="about"
          className="grid grid-cols-1 md:grid-cols-2 gap-2 mx-2 lg:mx-10 "
        >
          <div className=" flex flex-col  ">
            <div className="flex flex-col w-full lg:max-w-[400px] justify-between  h-full mx-auto gap-3">
              <div className="flex flex-row items-center gap-1 md:ml-8 ">
                <img src="/assets/vector.svg" className="max-w-[20px]" />
                <h1 className="text-slate_blue font-semibold ">ABOUT US</h1>
              </div>
              <div className="flex flex-col gap-3">
                <h1 className="font-bold text-2xl  max tracking-wider mt-3">
                  Deploy AI in Everyday clinical operations
                </h1>

                <p className="text-slate_gray font-light whitespace-pre-line">
                  {`We leverage advanced AI language models in partnership with biotechnology and pharmaceutical companies to create customized solutions that advance new medications – from discovery to reality. \n \n Our platform revolutionizes clinical development through cutting-edge technology and intelligent solutions. We offer a comprehensive suite of tools designed to streamline and enhance the drug development process. Our Trial Performance Copilot optimizes clinical trial execution, while our Smart Data Management system ensures efficient handling of complex research data. With our Regulatory Intelligence Engine, we keep you ahead of evolving compliance requirements. Additionally, our Major Submission Content Writer assists in preparing high-quality regulatory submissions. By integrating these advanced capabilities, we empower pharmaceutical companies and research organizations to accelerate drug development, improve trial outcomes, and navigate the regulatory landscape with confidence.`}
                </p>

                {/* <div className="flex flex-row items-center gap-5 my-4">
                  <div className="flex flex-row items-center gap-3">
                    <img src="/assets/direction.svg" className="max-w-[10px]" />
                    <h1 className="text-sm font-light">
                      24/7 Medical Services
                    </h1>
                  </div>

                  <div className="flex flex-row items-center gap-3">
                    <img src="/assets/direction.svg" className="max-w-[10px]" />
                    <h1 className="text-sm font-light">
                      Team of Professtionals
                    </h1>
                  </div>
                </div> */}
              </div>
              <div className="self-start w-auto rounded-md text-white bg-slate_blue py-3 px-2 flex items-center justify-center">
                book a demo
              </div>
            </div>
          </div>
          <div className=" flex justify-center">
            <img
              src="/assets/about_us.svg"
              className="object-cover w-full max-h-[500px] lg:max-w-[300px] xl:max-w-[400px]"
            />
          </div>
        </div>

        {/* {Benefits} */}

        <div className="flex flex-col  max-w-[1440px] my-6 ">
          <div className="flex flex-row items-center gap-1 mx-auto  ">
            <img src="/assets/vector.svg" className="max-w-[20px]" />
            <h1 className="text-slate_blue font-semibold ">
              Key Benefits of Our Platform
            </h1>
          </div>

          <p className="font-light tracking-wide text-center text-slate_gray my-3 w-full  sm:max-w-[500px] mx-auto px-[2px]">
            Clinical development: Smarter trials, better data, quicker results
          </p>

          <div className="  gap-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 px-3 mx-0 lg:mx-14">
            {benefits.map((benefit) => {
              return (
                <div className="felx flex-col cursor-pointer py-8 items-center hover:shadow-custom shadow-none   duration-300 group bg-[#E1E0E0] hover:bg-white rounded-lg">
                  <div className=" w-[70px] h-[70px] flex items-center justify-center duration-300 group-hover:bg-slate_blue bg-wenge rounded-full mx-auto">
                    <img
                      src={benefit?.image}
                      className="object-contain w-14 h-14"
                    />
                  </div>

                  <h1 className="text-slate_blue font-semibold text-center my-4">
                    {benefit.title}
                  </h1>
                  <p className="text-center text-slate_gray font-light w-[90%] mx-auto">
                    {benefit.description}
                  </p>

                  <div className="flex flex-row items-center lg:opacity-0 group-hover:opacity-100 duration-300  mt-6 justify-center mx-auto  gap-2">
                    <h1 className="font-light text-slate_blue">Read more</h1>
                    <GoArrowRight className="text-slate_blue text-xl" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Facilities */}
      <div className="w-full bg-gradient-to-b from-light_wenge to-steel_blue">
        <div className="max-w-[1440px]  flex flex-col my-4 px-3 mx-auto  lg:px-14">
          <div className="flex flex-row items-center gap-1  ">
            <img src="/assets/vector.svg" className="max-w-[20px]" />
            <h1 className="text-slate_blue font-semibold ">Use Case Study</h1>
          </div>

          <h1 className="tracking-wide text-2xl font-semibold mt-4 mb-4">
            Medical Writing Assistance
          </h1>

          <div className="flex flex-col gap-3 ">
            {/* <Facility1
              title="Emotionally Intelligent Speech Simulations"
              description="Our sophisticated AI models generate lifelike patient interactions with a wide range of emotional expressions, enabling you to practice delivering challenging news, discussing complex treatment plans, and handling sensitive conversations with empathy and confidence."
              // image="/assets/emotional_intelligence.svg"
              video="/assets/video.mp4"
            /> */}

            <div className="flex items-center justify-center px-20">
              <video
                ref={videoRef}
                muted
                autoPlay
                src={`${process.env.REACT_APP_S3_BUCKET_URL}/video.mp4`}
                controls
                className="w-full   rounded-xl sm:rounded-none overflow-hidden sm:w-auto h-auto   "
              ></video>
            </div>

            <div className="grid  grid-cols-1 sm:grid-cols-2 gap-4">
              <Facility2
                title="Clinical Operations Specific Models"
                description="Conduct detailed head-to-toe assessments on virtual patients, honing your ability to detect abnormalities and early signs of disease. Gain proficiency in performing focused examinations based on specific patient symptoms, including cardiovascular, respiratory, neurological, and musculoskeletal evaluations."
                image="/assets/patient_examination.svg"
              />

              <Facility2
                title="Crafting Diagnostic Hypotheses"
                description="Strengthen your diagnostic capabilities through interactive case studies, practicing the creation of differential diagnoses by evaluating and systematically dismissing various potential conditions."
                image="/assets/diagnosis.svg"
              />
            </div>
            <Facility1
              title="Security"
              description="Our system guarantees that nuanced and frequently overlooked cases, such as those involving underlying issues like substance abuse or mental health crises, are thoroughly addressed. By integrating bias analysis and communication skill evaluations, our feedback enhances both your clinical proficiency and empathetic skills."
              image="/assets/personalized.png"
            />
          </div>
        </div>
      </div>

      {/* {Testimonials} */}
      {/* <div className="max-w-[1440px] w-full   flex flex-col my-4 px-3 mx-auto  lg:px-14">
        <div className="flex flex-row items-center gap-1 mx-auto mt-6">
          <img src="/assets/vector.svg" className="max-w-[20px]" />
          <h1 className="text-slate_blue font-semibold text-center">
            CLIENT TESTIMONIALS
          </h1>
        </div>

        <h1 className="tracking-wider text-2xl font-bold mt-4 mb-4 text-center">
          Success Stories from Our Users
        </h1>

        <div className="mx-0 sm:mx-[50px] md:mx-[100px] lg:mx-[200px]">
          <Swiper
            modules={[Controller]}
            controller={{ control: swiper2 }}
            onSwiper={setSwiper1}
            onSlideChange={(e) => {
              setActiveIndex(e.activeIndex);
            }}
            className="swiper1 "
          >
            {reviews.map((review) => (
              <SwiperSlide className="bg-grose-500 w-full flex flex-col">
                <div className="flex flex-row gap-2 items-center">
                  <img
                    src={review.image}
                    className="max-w-16 w-16 h-16 max-h-16 rounded-full object-cover"
                  />
                  <div className="flex flex-col gap-1">
                    <h1 className="font-semibold">{review.name}</h1>
                    <div className="flex flex-row gap-2">
                      {[...Array(review.rating)].map((_, index) => {
                        return (
                          <img
                            src="/assets/star.svg"
                            className="w-4 max-w-4 object-cover"
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>

                <img
                  src="/assets/quote.svg"
                  className="my-6 max-w-16 object-cover"
                />

                <p className="text-center font-light text-[#6E6E6E]">
                  {review.text}
                </p>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="flex flex-row items-center mx-0 sm:mx-[80px] md:mx-[150px] lg:mx-[240px] mt-8 gap-4">
          <div
            className="min-w-14 min-h-14 cursor-pointer rounded-full bg-slate_blue bg-opacity-35 flex items-center justify-center"
            onClick={() => {
              swiper1?.slidePrev();
            }}
          >
            <FaArrowLeft
              className={`text-slate_blue duration-300 text-2xl ${
                activeIndex === 0 ? "opacity-60" : "opacity-100"
              }`}
            />
          </div>

          <Swiper
            modules={[Controller]}
            controller={{ control: swiper1 }}
            onSwiper={setSwiper2}
            slidesPerView={2}
            spaceBetween={20}
            centeredSlidesBounds={true}
            centeredSlides={true}
            breakpoints={{
              480: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            className="swiper1  "
          >
            {reviews.map((review, index) => (
              <SwiperSlide>
                <div
                  className={`w-full h-[80px] flex items-center justify-center`}
                >
                  <img
                    src={review.image}
                    className={`${
                      index === activeIndex
                        ? "w-[80px] h-[80px]"
                        : "w-[50px] h-[50px]"
                    } max-h-[80px] max-w-[80px] rounded-full duration-300  object-cover`}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div
            className="cursor-pointer min-w-14 min-h-14 rounded-full bg-slate_blue bg-opacity-35 flex items-center justify-center"
            onClick={() => {
              swiper1?.slideNext();
            }}
          >
            <FaArrowRight
              className={`text-slate_blue duration-300 text-2xl ${
                activeIndex === reviews.length - 1
                  ? "opacity-60"
                  : "opacity-100"
              }`}
            />
          </div>
        </div>
      </div> */}

      {/* Footer */}
      <div className="max-w-[1440px] w-full   flex flex-col my-4 px-3 mx-auto  lg:px-14">
        <div className="flex flex-row items-center gap-1 mx-auto mt-6">
          <img src="/assets/vector.svg" className="max-w-[20px]" />
          <h1 className="text-slate_blue font-semibold text-center">
            CONTACT US
          </h1>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-2 mt-4">
          <div className="flex flex-col gap-7">
            <h1 className="text-3xl font-semibold tracking-wider">
              Get in touch
            </h1>
            <div className="flex flex-row items-center gap-3 mt-4 ">
              <div className="w-12 h-12 rounded-full bg-slate_blue flex items-center justify-center">
                <HiOutlineMail className="text-white text-3xl" />
              </div>
              <div className="flex flex-col gap-[0px]">
                <h1 className="font-bold ">Email</h1>
                <h1 className="text-[#3D3D3D] font-light ">
                  alltrials@rwenow.com
                </h1>
              </div>
            </div>

            {/* <div className="flex flex-row items-center gap-3 ">
              <div className="w-12 h-12 rounded-full bg-slate_blue flex items-center justify-center">
                <LuPhoneCall className="text-white text-3xl" />
              </div>
              <div className="flex flex-col gap-[0px]">
                <h1 className="font-bold ">Call now</h1>
                <h1 className="text-[#3D3D3D] font-light ">+92123456789</h1>
              </div>
            </div> */}

            {/* <div className="flex flex-row items-center gap-3 ">
              <div className="w-12 h-12 rounded-full bg-slate_blue flex items-center justify-center">
                <IoLocationOutline className="text-white text-3xl" />
              </div>
              <div className="flex flex-col gap-[0px]">
                <h1 className="font-bold ">Location</h1>
                <h1 className="text-[#3D3D3D] font-light ">
                  12 street gt road aslian
                </h1>
              </div>
            </div> */}
          </div>

          <div
            id="contact"
            className="w-full rounded-xl bg-wenge bg-opacity-50 p-3 "
          >
            <div className="mr-auto flex justify-start">
              <h1 className="text-white font-semibold text-2xl border-b-[1px] border-b-white ">
                Get Appointment
              </h1>
            </div>
            <div className="flex flex-col p-2 gap-2 mt-4">
              <input
                placeholder="Name"
                className="bg-white p-2 rounded-md outline-none"
              />
              <input
                placeholder="Email"
                className="bg-white p-2 rounded-md outline-none"
              />
              <textarea
                placeholder="Message"
                className="bg-white p-2  rounded-md outline-none"
              />
              <div className="mx-auto mt-2 min-w-[160px]">
                <Button label="Start Now" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-gradient-to-b from-light_wenge to-steel_blue h-[250px]">
        <div className="max-w-[1440px] w-full h-full  mx-auto p-4 flex flex-col justify-between">
          <div className="grid grid-cols-2">
            <div className="flex flex-col gap-2 h-full ">
              <h1 className="text-slate_blue text-xl font-bold">
                AllTrials.ai
              </h1>
              <p className="text-gray-500 tracking-wider">
                We’re a team of pioneers and scholars from leading global
                medical institutions.
              </p>
            </div>
            <div className="flex flex-row items-center justify-center gap-5">
              <a href="#home" className="text-gray-500">
                Home
              </a>
              <a href="#contact" className="text-gray-500">
                Contact Us
              </a>
              <a href="#about" className="text-gray-500">
                About Us
              </a>
            </div>
          </div>

          <div className="grid grid-cols-2 mb-4">
            <div className="flex flex-row items-center gap-2">
              <MdEmail className="text-gray-500" />
              <h1 className="text-gray-500">support@alltrials.ai</h1>
            </div>

            <div className="flex flex-row items-center justify-center gap-5">
              <a href="#home" className="text-gray-500">
                Terms & conditions
              </a>
              <a href="#home" className="text-gray-500">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
