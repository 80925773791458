import { doc, updateDoc } from "firebase/firestore";

import { BiMenuAltLeft } from "react-icons/bi";
import Button from "../../components/Button";
import Input from "../../components/Input";
import React from "react";
import { db } from "../../utils/firebase";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import useSiderbar from "../../hooks/useSidebar";
import useUser from "../../hooks/useUser";

const Profile = () => {
  const { user, setUser } = useUser();
  const { isSidebarOpen, setIsSidebarOpen } = useSiderbar();

  const onSubmit = async (data) => {
    console.log(data);
    try {
      const userRef = doc(db, "users", user?.id);
      await updateDoc(userRef, {
        name: data?.name,
      });

      setUser({ ...user, name: data?.name });

      toast.success("Updated successfully");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    defaultValues: {
      name: user?.name,
      email: user?.email,
    },
  });

  return (
    <div className="h-full   flex items-center justify-center">
      <div
        style={{
          boxShadow: `-1px 2px 4px 0px rgba(0,0,0,0.4)`,
        }}
        className="w-full mx-0 h-full sm:h-auto sm:mx-14 flex-col md:mx-32 flex justify-start sm:justify-center shadow-2xl bg-white bg-opacity-100 sm:bg-opacity-20 rounded-none sm:rounded-xl"
      >
        <div className="w-full m-2 min-h-[32px] sm:mb-auto flex flex-row items-center justify-between  ">
          <BiMenuAltLeft
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="text-3xl cursor-pointer    block"
          />
        </div>

        <div className="flex flex-col w-[80%] md:w-[60%] mx-auto my-10">
          {/* <h1 className="text-lg font-semibold">{user?.name}</h1>
          <p className="font-light mt-2 mb-10">Personal Information</p> */}
          <h1 className="text-lg font-semibold mb-10">Personal Information</h1>

          <form className="gap-4 flex flex-col w-full my-2 ">
            <div className="flex flex-col gap-1">
              <h1 className="ml-1 tracking-wider font-light text-slate_gray">
                Name
              </h1>

              <Input
                // style={{ color: "var(--offWhite)", fontWeight: "bold" }}
                // classNames="placeholder:text-offWhite"
                id="name"
                label={"Name"}
                placeholder={"Enter your name"}
                required
                register={register}
                errors={errors}
              />
            </div>

            <div className="flex flex-col gap-1">
              <h1 className="ml-1 tracking-wider font-light text-slate_gray">
                Email
              </h1>

              <Input
                // style={{ color: "var(--offWhite)", fontWeight: "bold" }}
                // classNames="placeholder:text-offWhite"
                id="email"
                label={"Email"}
                placeholder={"Enter your email"}
                required={false}
                register={register}
                errors={errors}
                disabled={true}
              />
            </div>

            <div className="w-[200px] mt-5">
              <Button onClick={handleSubmit(onSubmit)} label={"Save Changes"} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Profile;
