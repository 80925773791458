import React from "react";
import clsx from "clsx";

function Input({
  id,
  label,
  type,
  required,
  register,
  errors,
  disabled,
  LeftIcon,
  RightIcon,
  placeholder,
  classNames,
  ...other
}) {
  
  return (
    <div className="flex flex-col ">
      <div
        className={clsx(
        `
        p-2
       py-3
        flex 
        flex-row
        items-center
        form-input
        w-full
        rounded-[10px]
      
       
  
        border-[2px]
        
      
        `,
          !errors[id] && "focus:ring-rose-500 ",
          errors[id] ? " border-rose-500 " : "border-slate_blue",
          disabled && "opacity-50 cursor-not-allowed"
        )}
      >

       
        {LeftIcon && <LeftIcon />}
        <input
          {...other}
          id={id}
          placeholder={placeholder}
          type={type}
          autoComplete={id}
          disabled={disabled}
          {...register(id, { required })}
          className={clsx(
            `w-full
            outline-none
            bg-transparent
            text-gray-600
            mx-2
            placeholder:text-gray-500
            
            ${classNames}
           
        `
          )}
        />
        {RightIcon && <RightIcon />}
      </div>
      {errors[id] && <p className="text-rose-500 ">{`${errors[id]?.message}`}</p>}
    </div>
  );
}

export default Input;
