export function truncateString(str, limit) {
  // Check if the string length is greater than the specified limit
  if (str.length > limit) {
    // Truncate the string and add ellipsis
    return str.substring(0, limit) + "...";
  }

  // Return the original string if it's within the limit
  return str;
}

export const formatDate = (date) => {
  if (!date) return null;
  return date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
};


export const formatTime = (date) => {
  if (!date) return null;
  return date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false
  });
};

export const formatDateTime = (date) => {
  if(!date) return null;
  return formatDate(date) + ' ' + formatTime(date);
}