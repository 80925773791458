import { Route, Routes } from "react-router-dom";

import Dashboard from "../screens/app/dashboard";
import Discovery from "../screens/app/discovery";
import History from "../screens/app/history";
import Profile from "../screens/app/profile";
import { Toaster } from "react-hot-toast";
import Users from "../screens/app/admin/users";
import useUser from "../hooks/useUser";
import Agent from "../screens/app/agent";
import WordAIEditor from "../screens/app/agent/wordAIEditor";

const NotFound = () => {
  return (
    <div className="flex h-full w-full  items-center justify-center text-3xl font-bold">
      Not Found!
    </div>
  );
};

export function AppNavigation() {
  const { user } = useUser();
  return (
    <div className="h-full">
      <Toaster />
      {user?.type == "admin" ? (
        <Routes>
          <Route path="/" element={<Users />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/history" element={<History />} />
          <Route path="/discovery" element={<Discovery />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/agent" element={<Agent />} />
          <Route path="/word-ai-editor" element={<WordAIEditor />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      )}
    </div>
  );
}
