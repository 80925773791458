import { useEffect } from "react";
import "../../../styles/wordAIEditor.scss";

const WordAIEditor = () => {
  useEffect(() => {
    // Dynamically create a script tag
    const script = document.createElement("script");
    window.apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    script.src = `/lib/js/wordAIEditor.js`; // Specify the path to your JS file in the public folder
    script.async = true;

    // Append the script tag to the document body
    document.body.appendChild(script);

    // Cleanup: Remove the script tag when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="word-ai-editor">
      <div className="container">
        <div className="sidebar">
          <h2>WordAI Editor</h2>
          <select id="reportType">
            <option value="">Select Report Type</option>
            <option value="clinicalTrialProtocol">
              Clinical Trial Protocol
            </option>
            <option value="clinicalStudyReport">Clinical Study Report</option>
          </select>
          <select id="llmModel">
            <option value="">Select LLM Model</option>
            <option value="writerMode">Writer Mode</option>
            <option value="editing">Editing</option>
            <option value="qc">QC</option>
            <option value="tabularData">Tabular Data</option>
          </select>
          <button id="generateContent">Generate Content</button>
          <button id="saveDocument">Save Document</button>
          <button id="uploadDocument">Upload Document</button>
        </div>
        <div className="main-content">
          <div className="toolbar">
            <button data-command="bold">
              <strong>B</strong>
            </button>
            <button data-command="italic">
              <em>I</em>
            </button>
            <button data-command="underline">
              <u>U</u>
            </button>
            <button data-command="insertUnorderedList">• List</button>
            <button data-command="insertOrderedList">1. List</button>
            <button data-command="createTable">Table</button>
          </div>
          <div className="document" contenteditable="true" id="documentContent">
            <h1>Your Medical Document Title</h1>
            <p>
              Start typing here or use the sidebar to generate AI-powered
              content for your clinical document.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WordAIEditor;
