import "react-datepicker/dist/react-datepicker.css";

import Input from "../../Input";
import Modal from "../modal";
import { auth } from "../../../utils/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useState } from "react";
import useTopMatch from "../../../hooks/useTopMatch";
import useTopMatchModal from "../../../hooks/modal/useTopMatchModal";

function TopMatchModal() {
  const topMatchModal = useTopMatchModal()
  
const {topMatch:match}=useTopMatch()
  

  

  const bodyContent = (
    <div className=" flex flex-col gap-3   bg-white">
                  {/* {JSON.stringify(match)} */}
                  <h1 className="font-semibold">
                    NCT Number :{" "}
                    <span className="font-normal text-gray-500">
                      {match?.["NCT Number"]}
                    </span>
                  </h1>
                  <h1 className="font-semibold">
                    Study Title :{" "}
                    <span className="font-normal text-gray-500">
                      {match?.["Study Title"]}
                    </span>
                  </h1>
                  <h1 className="font-semibold">
                    Study URL :{" "}
                    <span className="font-normal text-gray-500">
                      {match?.["Study URL"]}
                    </span>
                  </h1>
                </div>
  );

  return (
    <Modal
      isOpen={topMatchModal.isOpen}
      onClose={topMatchModal.onClose}
      body={bodyContent}
      title={"Top Match"}
    />
  );
}

export default TopMatchModal

