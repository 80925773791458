import {
  collection,
  getDocs,
  orderBy,
  query,
  limit,
  startAfter,
  where,
  getCountFromServer,
} from "firebase/firestore";
import { useCallback, useMemo, useState } from "react";

import { BiMenuAltLeft } from "react-icons/bi";
import { db } from "../../../utils/firebase";
import useSiderbar from "../../../hooks/useSidebar";
import TablePagination from "../../../components/TablePagination";
import { formatDateTime } from "../../../utils/helper";

const Users = () => {
  const [allUsers, setAllUsers] = useState([]);
  const { isSidebarOpen, setIsSidebarOpen } = useSiderbar();
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRow, setTotalRow] = useState(0);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "index",
        disableSortBy: true,
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Created At",
        accessor: "createdAt",
      },
      {
        Header: "Last Logged in at",
        accessor: "lastLoggedInAt",
      },
    ],
    []
  );
  const getLastVisibleDoc = async (pageIndex, pageSize, search, sortBy) => {
    let skipUsersQuery = query(
      collection(db, "users"),
      limit(pageIndex * pageSize)
    );
    if (sortBy.length > 0)
      skipUsersQuery = query(
        skipUsersQuery,
        orderBy(sortBy[0].id, sortBy[0].desc ? "desc" : "asc")
      );
    const userSnapshot = await getDocs(skipUsersQuery);
    return userSnapshot.docs[userSnapshot.docs.length - 1]; // Last visible document
  };

  const getUserCount = async (search) => {
    let userRef = query(collection(db, "users"));
    const userSnapshot = await getCountFromServer(userRef);

    userRef = query(userRef, where("type" , "==", "admin"));
    const adminSnapshot = await getCountFromServer(userRef);

    return userSnapshot.data().count - adminSnapshot.data().count;
  };
  const getUsers = useCallback(async (pageSize, pageIndex, search, sortBy) => {
    setLoading(true);
    let usersQuery = query(collection(db, "users"));
    // Initialize query
    if (sortBy.length > 0)
      usersQuery = query(
        usersQuery,
        orderBy(sortBy[0].id, sortBy[0].desc ? "desc" : "asc") // Sorting by field and direction
      );

    // Implement pagination: Skip pages by using 'startAfter' and limit the number of docs
    if (pageIndex > 0) {
      // Get the last document from the previous page
      const lastVisibleDoc = await getLastVisibleDoc(
        pageIndex,
        pageSize,
        search,
        sortBy
      );
      if (lastVisibleDoc) {
        usersQuery = query(usersQuery, startAfter(lastVisibleDoc));
      }
    }

    // Limit the number of documents per page
    usersQuery = query(usersQuery, limit(pageSize));

    const userSnapshot = await getDocs(usersQuery);
    const usersTemp = [];
    let index = 0;
    userSnapshot.forEach((user) => {
      const data = user.data();

      const createdAt = data.createdAt?.toDate();
      const lastLoggedInAt = data.lastLoggedInAt?.toDate();

      usersTemp.push({
        id: user?.id,
        index: ++index,
        ...data,
        createdAt: formatDateTime(createdAt),
        lastLoggedInAt: formatDateTime(lastLoggedInAt),
      });
    });
    const count = await getUserCount(search);

    setLoading(false);
    setAllUsers(usersTemp.filter((user) => user?.type != "admin"));
    setPageCount(Math.ceil(count * 1.0 / pageSize));
    setTotalRow(count);
  }, []);

  return (
    <div className="w-full h-full overflow-hidden flex flex-col relative">
      <div className="w-full min-h-[32px] flex flex-row justify-between  ">
        <BiMenuAltLeft
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="text-3xl cursor-pointer    block"
        />
      </div>

      <div className="flex flex-row items-center gap-2 m-2">
        <h1 className="font-semibold min-w-[140px]">Users</h1>
      </div>

      <div className="w-full min-h-[1px] bg-light_grey mt-4 " />
      {/* <div className="grid grid-cols-2 gap-4 overflow-y-scroll pt-4">
        {allUsers?.map((user) => {
          return (
            <div className="flex flex-col gap-3 p-3 bg-white rounded-xl shadow-sm">
              <h1 className="font-semibold">
                Name :{" "}
                <span className="font-normal text-gray-500">{user?.name}</span>
              </h1>
              <h1 className="font-semibold">
                Email :{" "}
                <span className="font-normal text-gray-500">{user?.email}</span>
              </h1>
            </div>
          );
        })}
      </div> */}

      <div class="container mx-auto p-4 overflow-y-auto">
        <div class="overflow-x-auto">
          <TablePagination
            columns={columns}
            data={allUsers}
            fetchData={getUsers}
            loading={loading}
            pageCount={pageCount}
            totalRow={totalRow}
          />
        </div>
      </div>
    </div>
  );
};

export default Users;
