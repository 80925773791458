import { BsUpload } from "react-icons/bs";
import Button from "../../Button";
import { IoClose } from "react-icons/io5";
import Modal from "../modal";
import { auth } from "../../../utils/firebase";
import { signOut } from "firebase/auth";
import useLogoutModal from "../../../hooks/modal/useLogoutModal";
import { useNavigate } from "react-router-dom";

function LogoutModal() {
  const logoutModal = useLogoutModal();
  const navigate=useNavigate()

  const bodyContent = (
    <div className="flex flex-col gap-6">
      <IoClose
        onClick={() => logoutModal.onClose()}
        className="ml-auto cursor-pointer text-xl text-slate_blue"
      />
      <div className="flex items-center justify-center mx-auto p-4 rounded-full bg-slate_blue bg-opacity-15">
        <BsUpload className="rotate-90 text-xl text-slate_blue" />
      </div>

      <h1 className="text-center text-lg">Are you sure you want to log out?</h1>

      <div className="flex flex-row items-center justify-center gap-4">
        <Button
          label="Cancel"
          onClick={() => logoutModal.onClose()}
          outline
          small
        />

        <Button
          label="Logout"
          onClick={() => {
            logoutModal.onClose();
            signOut(auth);
            navigate('/login')
          }}
          small
        />
      </div>
    </div>
  );

  return (
    <Modal
      isOpen={logoutModal.isOpen}
      onClose={logoutModal.onClose}
      body={bodyContent}
    />
  );
}

export default LogoutModal;
