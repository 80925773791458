import { auth, db } from "./utils/firebase";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import toast, { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";

import { AppNavigation } from "./navigation/appNavigation";
import { AuthNavigation } from "./navigation/authNavigation";
import Home from "./screens/app/home";
import { onAuthStateChanged } from "firebase/auth";
import useAuthAction from "./hooks/useAuthAction";
import { useNavigate } from "react-router-dom";
import useUser from "./hooks/useUser";
import { Oval } from "react-loader-spinner";

// import bgImage from './background.png'

const App = () => {
  const { user: currentUser, setUser } = useUser();
  const [userTemp, setUserTemp] = useState("");
  const { authAction, setAuthAction } = useAuthAction();
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserTemp(user);
        setAuthAction("auto_login");
      } else {
        setUser(null);
        // navigate('/')
      }
    });
  }, []);

  const getUserData = async () => {
    try {
      const userDoc = await getDoc(doc(db, "users", auth?.currentUser?.uid));

      if (userDoc.exists()) {
        setUser({ id: userDoc.id, ...userDoc.data() });
        navigate("/");
        if (authAction === "login") toast.success("Logged In Successfully");
      } else {
        setUser(null);
        console.log("No such document!");
      }
    } catch (error) {
      setUser(null);
      toast.error("Something went wrong.");
      console.error("Error fetching document:", error);
    }
  };

  useEffect(() => {
    if (auth?.currentUser) {
      // const unsub = onSnapshot(
      //   doc(db, "users", auth?.currentUser?.uid),
      //   async (doct) => {
      //     setUser({ id: doct?.id, ...doct?.data() });
      //     navigate('/')
      //   }
      // );
      // return unsub;

      getUserData();
    }
  }, [authAction]);

  return (
    <div className="h-screen overflow-y-scroll no-scrollbar">
      <Toaster />
      {currentUser ? (
        currentUser == "loading" ? (
          <div className="h-screen w-screen flex justify-center items-center">
            <Oval
              visible={true}
              height="60"
              width="60"
              color="#706aee"
              secondaryColor="#b3b5db"
              ariaLabel="oval-loading"
            />
          </div>
        ) : (
          <Home />
        )
      ) : (
        <AuthNavigation />
      )}
    </div>
  );
};

export default App;
