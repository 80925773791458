import { Oval } from "react-loader-spinner";
import React from "react";

function Button({ label, onClick, disabled, outline, small, icon: Icon }) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`
              relative
              disabled:opacity-70
              disabled:cursor-not-allowed
              rounded-lg
              
              transition
              w-full
              flex
              justify-center
              items-center
              cursor-poitner
              hover:translate-y-[1px]
              px-2

            ${outline ? "bg-white" : "bg-slate_blue"}
            ${outline ? "border-slate_blue" : "border-slate_blue"}
            ${outline ? "text-slate_blue" : "text-white"}
            ${small ? "py-1" : "py-3"}
            ${small ? "text-sm" : "text-md"}
            ${small ? "font-light" : "font-normal"}
            
            ${outline ? (small ? "border-[1px]" : "border-2") : "border-2"}


          `}
    >
      {!disabled ? (
        <div className="h-[24px]">
          {Icon && <Icon size={24} className="absolute left-4 top-3" />}
          {label}
        </div>
      ) : (
        <Oval
          visible={true}
          height="24"
          width="24"
          color="#fff"
          secondaryColor="#b3b5db"
          ariaLabel="oval-loading"
        />
      )}
    </button>
  );
}

export default Button;
