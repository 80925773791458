import React, { useState } from "react";

import { BiMenuAltLeft } from "react-icons/bi";
import { RiSearchLine } from "react-icons/ri";
import studies from "../../utils/data.json";
import useSiderbar from "../../hooks/useSidebar";

const Discovery = () => {
  const { isSidebarOpen, setIsSidebarOpen } = useSiderbar();
  const [search, setSearch] = useState("");
  const [openSearch, setOpenSearch] = useState(false);


  const [filteredStudies, setFilteredStudies] = useState(studies);

  // Function to filter studies based on search term
  const filterStudies = (search) => {
    if (!search) {
      // If search is empty, return the original list
      setFilteredStudies(studies);
      return;
    }

    const lowerCaseSearch = search.toLowerCase();
    
    const filtered = studies.filter((study) => {
      return Object.values(study).some(value => 
        typeof value === 'string' && value.toLowerCase().includes(lowerCaseSearch)
      );
    });

    setFilteredStudies(filtered);
  };

  
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    filterStudies(value);
  };



  return (
    <div className="w-full h-full    overflow-hidden flex flex-col relative">
      <div className="w-full   min-h-[32px] flex flex-row justify-between  ">
        <BiMenuAltLeft
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="text-3xl cursor-pointer    block"
        />
      </div>

      <div className="flex flex-row items-center gap-2 m-2">
        <h1 className="font-semibold min-w-[140px]">Discovered Trails</h1>
        <div className="flex flex-row duration-300 w-full md:w-[70%]   rounded-xl">
          <div
            className={`flex duration-300 h-10 ${
              openSearch ? "flex-grow w-auto" : "flex-grow-0 w-10"
            }  overflow-hidden bg-white px-0 rounded-xl shadow-lg `}
          >
            <input value={search} onChange={handleInputChange} className={`flex w-full ${openSearch?'p-2':'p-0'} outline-none `} />
            <div
              onClick={() => setOpenSearch(!openSearch)}
              className="cursor-pointer min-w-10 w-10 rounded-xl overflow-hidden flex items-center justify-center"
            >
              <RiSearchLine className="text-xl"/>
            </div>
          </div>
        </div>
        
      </div>

      <div className="w-full min-h-[1px] bg-light_grey mt-4 "/>
      
      <div className="flex flex-grow flex-col gap-4 overflow-y-scroll no-scrollbar  mx-2 sm:mx-6 md:mx-16 pt-4">
      {filteredStudies.map(doc=>{
        return <div className="flex flex-col gap-3 p-3 bg-white rounded-2xl">
         <p className=" font-light">{doc?.['Study Title']}</p>
         <h1 className="font-bold text-sm">NCT Number:  <span className="font-light ml-2">{doc?.['NCT Number']}</span></h1>
         <h1 className="font-bold text-sm">Sponsored by:  <span className="font-light ml-2">{doc?.['Sponsor']}</span></h1>
        </div>
      })}
      </div>
    </div>
  );
};

export default Discovery;
