import "react-datepicker/dist/react-datepicker.css";

import Input from "../../Input";
import Modal from "../modal";
import { auth } from "../../../utils/firebase";
import { confirmPasswordReset } from "firebase/auth";
import toast from "react-hot-toast";
import useConfirmResetPasswordModal from "../../../hooks/modal/useConfirmResetPasswordModal";
import { useForm } from "react-hook-form";
import { useState } from "react";

function ConfirmResetPasswordModal({ actionCode }) {
  const confirmUpdatePasswordModal = useConfirmResetPasswordModal();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      new_password: "",
      confirm_new_password: "",
    },
  });

  const onSubmit = async (data) => {
    
    try {
      if (data?.new_password !== data?.confirm_new_password) {
        toast.error("Please confirm password again");
        return;
      }
      console.log(actionCode)
      setIsLoading(true);
      await confirmPasswordReset(auth, actionCode, data?.new_password);
      toast.success("Password Updated");
      reset();

      confirmUpdatePasswordModal.onClose();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);

      toast.error(error?.code);
    }
  };

  const bodyContent = (
    <div className="flex flex-col gap-4">
      <Input
        id="new_password"
        label="Password"
        type="text"
        errors={errors}
        register={register}
        required={true}
        placeholder="Password"
      />

      <Input
        id="confirm_new_password"
        label="Confirm Password"
        type="text"
        errors={errors}
        register={register}
        required={true}
        placeholder="Confirm Password"
      />
    </div>
  );

  return (
    <Modal
      isOpen={confirmUpdatePasswordModal.isOpen}
      onClose={confirmUpdatePasswordModal.onClose}
      onSubmit={handleSubmit(onSubmit)}
      title={"Password Reset"}
      actionLabel="Reset"
      disabled={isLoading}
      body={bodyContent}
    />
  );
}

export default ConfirmResetPasswordModal;
