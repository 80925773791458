import { IoDocumentOutline, IoLogOutOutline } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";

import { AppNavigation } from "../../navigation/appNavigation";
import { BiMenuAltLeft } from "react-icons/bi";
import { BsPerson } from "react-icons/bs";
import LogoutModal from "../../components/modal/app/logoutModal";
import { LuSearch } from "react-icons/lu";
import React from "react";
import { TbSmartHome } from "react-icons/tb";
import { SiTraefikproxy } from "react-icons/si";
import { auth } from "../../utils/firebase";
import { signOut } from "firebase/auth";
import useLogoutModal from "../../hooks/modal/useLogoutModal";
import useSiderbar from "../../hooks/useSidebar";
import useUser from "../../hooks/useUser";

const Home = () => {
  const { pathname } = useLocation();
  const {isSidebarOpen,setIsSidebarOpen}=useSiderbar()
  const {user}=useUser()
  const logoutModal=useLogoutModal()

  const menuOptions = user?.type=='admin'?[
    {
      title: "Users",
      link: "/",
      icon: <BsPerson className="text-[24px] " />,
    }
  ]:[
    {
      title: "Dashboard",
      link: "/",
      icon: <TbSmartHome className="text-[24px] " />,
    },
    {
      title: "Discovery",
      link: "/discovery",
      icon: <LuSearch className="text-[24px] " />,
    },
    {
      title: "Past History",
      link: "/history",
      icon: <IoDocumentOutline className="text-[24px] " />,
    },
    {
      title: "Agent OS",
      link: "/agent",
      icon: <SiTraefikproxy className="text-[24px] " />,
    },
    {
      title: "Profile",
      link: "/profile",
      icon: <BsPerson className="text-[24px] " />,
    },
  ];

  return (
    <div className="w-screen h-screen flex flex-row">
      <div className={`h-full ${isSidebarOpen?"min-w-64 w-64":"w-0 min-w-0"} max-w-64 duration-300 bg-slate_blue flex flex-col justify-between overflow-y-scroll no-scrollbar  lg:relative absolute z-10`}>
        
      <BiMenuAltLeft onClick={()=>setIsSidebarOpen(false)} className="text-3xl cursor-pointer text-white m-7 lg:hidden block" />
        
        <div className="flex flex-col gap-2 mt-4 lg:mt-8">
          {menuOptions?.map((opt) => (
            <Link
              to={opt?.link}
              className="flex flex-row  items-center   text-white cursor-pointer"
            >
              <div
                className={`h-full w-[3px] bg-white rounded-r-full duration-300 ${
                  pathname === opt?.link ? "opacity-100" : "opacity-0"
                }`}
              />
              <div
                className={`flex flex-row gap-3 items-center justify-center ml-14 py-6 duration-300 ${
                  pathname === opt?.link ? "text-white" : ""
                }text-light_grey`}
              >
                {opt?.icon}
                <h1 className="font-light">{opt?.title}</h1>
              </div>
            </Link>
          ))}
        </div>

        <div className="flex flex-row  items-center   text-white cursor-pointer">
          <div
            onClick={() => logoutModal.onOpen()}
            className={`flex flex-row gap-3 ml-[60px] items-center justify-center py-2 mb-4 duration-300 text-light_grey`}
          >
            <IoLogOutOutline className="text-[24px] " />
            <h1 className="font-light">Logout</h1>
          </div>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto bg-green-200 bg-gradient-to-b from-light_wenge to-steel_blue p-0 lg:p-4">
        <AppNavigation />
      </div>
      <LogoutModal />
    </div>
  );
};

export default Home;
