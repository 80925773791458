import {
  ArrowLongDownIcon,
  ArrowLongUpIcon,
  FunnelIcon,
  ChevronRightIcon,
  ChevronLeftIcon
} from "@heroicons/react/24/outline";
import { ClockIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import {
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

function TablePagination({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  totalRow,
  actions: Actions,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter, sortBy },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualGlobalFilter: true,
      manualSortBy: true,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        sortBy: [
          {
            id: "name",
            desc: false,
          },
        ],
      }, // Pass our hoisted table state
      pageCount: controlledPageCount,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const GlobalFilter = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) => {
    const count = preGlobalFilteredRows;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 700);

    return (
      <div
        className={
          Actions !== undefined
            ? "flex flex-row justify-between"
            : "flex flex-col"
        }
      >
        {Actions !== undefined ? <Actions /> : null}
        <input
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} records...`}
          type="search"
          className={`input input-bordered input-sm w-full max-w-xs focus:outline-0 mb-2 ${
            Actions !== undefined ? "" : "self-end"
          }`}
        />
      </div>
    );
  };

  useEffect(() => {
    let search = globalFilter === undefined ? "" : globalFilter;
    fetchData(pageSize, pageIndex, search, sortBy);
  }, [fetchData, pageIndex, pageSize, globalFilter, sortBy]);

  return (
    <>
      {/* <GlobalFilter
        preGlobalFilteredRows={totalRow}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      /> */}
      <div className="overflow-x-auto relative rounded-xl">
        <table
          {...getTableProps()}
          className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
        >
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    scope="col"
                    className="py-3 px-6"
                  >
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowLongDownIcon className="h-4 w-4 inline mr-1" />
                        ) : (
                          <ArrowLongUpIcon className="h-4 w-4 inline mr-1" />
                        )
                      ) : (
                        <FunnelIcon className="h-4 w-4 inline mr-1" />
                      )}
                    </span>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.length > 0 ? (
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="bg-white border-b dark:border-gray-700"
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="py-4 px-6">
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr className="hover">
                <td colSpan={10000} className="text-center">
                  Data not found!
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {loading ? (
          <div className="absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-5 rounded-md z-20 flex items-center justify-center">
            <div className="absolute p-3 bg-white w-36 shadow-md rounded-md text-center">
              <div className="flex animate-pulse">
                <ClockIcon className="w-6 h-6 mr-1" /> <span>Loading...</span>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="flex flex-row justify-between">
        <div className="mt-2">
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageCount}
            </strong>{" "}
            Total <strong>{totalRow}</strong>{" "}
          </span>
          <span>
            | Jump to page:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              className="input input-bordered input-sm w-20 max-w-xs focus:outline-0"
            />
          </span>{" "}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="select select-bordered select-sm w-30 max-w-xs focus:outline-0"
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize} rows
              </option>
            ))}
          </select>
        </div>
        <div className="mt-2">
          <button
            className="rounded-md bg-[#1DA1F2] py-2 px-2.5 border border-transparent text-center text-sm text-white transition-all shadow-sm hover:shadow-lg focus:bg-[#1DA1F2]/90 focus:shadow-none active:bg-[#1DA1F2]/90 hover:bg-[#1DA1F2]/90 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none" type="button"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </button>{" "}
          <button
            className="rounded-md bg-[#1DA1F2] py-2 px-2.5 border border-transparent text-center text-sm text-white transition-all shadow-sm hover:shadow-lg focus:bg-[#1DA1F2]/90 focus:shadow-none active:bg-[#1DA1F2]/90 hover:bg-[#1DA1F2]/90 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none" type="button"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"<"}
          </button>{" "}
          <button
            className="rounded-md bg-[#1DA1F2] py-2 px-2.5 border border-transparent text-center text-sm text-white transition-all shadow-sm hover:shadow-lg focus:bg-[#1DA1F2]/90 focus:shadow-none active:bg-[#1DA1F2]/90 hover:bg-[#1DA1F2]/90 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none" type="button"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {">"}
          </button>{" "}
          <button
            className="rounded-md bg-[#1DA1F2] py-2 px-2.5 border border-transparent text-center text-sm text-white transition-all shadow-sm hover:shadow-lg focus:bg-[#1DA1F2]/90 focus:shadow-none active:bg-[#1DA1F2]/90 hover:bg-[#1DA1F2]/90 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none" type="button"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>{" "}
        </div>
      </div>
    </>
  );
}

export default TablePagination;
