import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

import Button from "../../components/Button";
import ConfirmResetPasswordModal from "../../components/modal/app/confirmResetPasswordModal";
import Input from "../../components/Input";
import ResetPasswordModal from "../../components/modal/app/resetPasswordModal";
import { auth, db } from "../../utils/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import toast from "react-hot-toast";
import useAuthAction from "../../hooks/useAuthAction";
import useConfirmResetPasswordModal from "../../hooks/modal/useConfirmResetPasswordModal";
import { useForm } from "react-hook-form";
import useResetPasswordModal from "../../hooks/modal/useResetPasswordModal";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";

function Login() {
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [searchParams] = useSearchParams();
  const { setAuthAction } = useAuthAction();

  const resetPasswordModal = useResetPasswordModal();
  const confirmPasswordResetModal = useConfirmResetPasswordModal();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const user = await signInWithEmailAndPassword(auth, data?.email, data?.password);
      await updateDoc(doc(db, "users", user?.user?.uid), {
        lastLoggedInAt: serverTimestamp()
      });
      setAuthAction("login");
      // setIsLoading(false);
    } catch (error) {
      console.log(error.code);
      setIsLoading(false);
      if (error.code === "auth/invalid-credential") {
        toast.error("Invalid  Credentials!");
      } else {
        toast.error("Something Went Wrong");
      }
    }
  };

  const handleConfirmPasswordReset = (actionCode) => {
    if (!actionCode) {
      return;
    }

    confirmPasswordResetModal.onOpen();
  };

  useEffect(() => {
    const mode = searchParams?.get("mode");
    const actionCode = searchParams?.get("oobCode");

    if (mode === "resetPassword") {
      handleConfirmPasswordReset(actionCode);
    }
  }, []);

  return (
    <div className="grid grid-cols-6 w-screen h-screen flex-row">
      <div className="h-full  items-center justify-center px-5 col-span-2 bg-gradient-to-b from-light_wenge to-steel_blue hidden md:flex">
        <img src="/assets/aichat.svg" className="object-cover mx-5" />
      </div>

      {/* bg-white */}
      <div className=" h-full col-span-6 md:col-span-4 overflow-y-scroll no-scrollbar  bg-gradient-to-b from-light_wenge md:from-white to-steel_blue md:to-white ">
        <div
          className="
          w-[90%]
          md:w-[50%]
            
            mt-[5%]
            mx-auto
            md:mx-2
            md:ml-[10%]
            
            flex
            flex-col 
            items-center 
            "
        >
          <div className=" flex flex-col gap-1  w-full mt-6 md:mt-0 mb-4 md:mb-16  text-center md:text-left">
            <h1 className="text-4xl font-bold ">Welcome Back!</h1>
            <p className="text-slate_gray font-light">
              Enter your email and password to login.
            </p>
          </div>

          <form className="gap-4 flex flex-col w-full my-2 ">
            <div className="flex flex-col gap-1">
              <h1 className="ml-1 tracking-wider">Email</h1>

              <Input
                // style={{ color: "var(--offWhite)", fontWeight: "bold" }}
                // classNames="placeholder:text-offWhite"
                id="email"
                label={"Email"}
                placeholder={"Enter your email"}
                required
                register={register}
                errors={errors}
              />
            </div>

            <div className="flex flex-col gap-1">
              <h1 className="ml-1 tracking-wider ">Password</h1>
              <Input
                id="password"
                label={"Password"}
                type={!showPassword && "password"}
                placeholder={"Enter your password"}
                required
                register={register}
                errors={errors}
                RightIcon={() => (
                  <div
                    onClick={() => setShowPassword(!showPassword)}
                    className="cursor-pointer"
                  >
                    {showPassword ? (
                      <IoMdEye className="text-lg" />
                    ) : (
                      <IoMdEyeOff className="text-lg" />
                    )}
                  </div>
                )}
              />
            </div>

            <div className="w-[250px] mt-2 md:mt-8 self-center md:self-start">
              <Button
                disabled={isLoading}
                label="Login"
                onClick={handleSubmit(onSubmit)}
              />
            </div>

            <div className="flex flex-row  items-center">
              <p className="text-offWhite">Don't have an account?</p>
              <Link
                to="/signup"
                className="text-slate_blue text-xl underline cursor-pointer ml-1"
              >
                Register
              </Link>
            </div>

            <div
              onClick={() => resetPasswordModal.onOpen()}
              className="flex flex-row  cursor-pointer"
            >
              <p className="text-slate_blue underline  text-xl">
                {/* Don't have an account? */}
                Forgot Password?
              </p>
            </div>
          </form>
        </div>
      </div>
      <ResetPasswordModal />
      <ConfirmResetPasswordModal actionCode={searchParams?.get("oobCode")} />
    </div>
  );
}

export default Login;
