import { Link, useSearchParams } from "react-router-dom";
import { Oval, ThreeDots } from "react-loader-spinner";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db, storage } from "../../utils/firebase";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";

import { AiFillCloseCircle } from "react-icons/ai";
import { BiMenuAltLeft } from "react-icons/bi";
import Button from "../../components/Button";
import { FaRegEdit } from "react-icons/fa";
import { FiFileText } from "react-icons/fi";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { IoCameraOutline } from "react-icons/io5";
import { IoChevronDown } from "react-icons/io5";
import { IoChevronForward } from "react-icons/io5";
import { IoChevronUp } from "react-icons/io5";
import { IoPaperPlaneOutline } from "react-icons/io5";
import { IoPersonCircleOutline } from "react-icons/io5";
import { IoPersonOutline } from "react-icons/io5";
import { LuUpload } from "react-icons/lu";
import ReactMarkdown from "react-markdown";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbLayoutSidebar } from "react-icons/tb";
import TopMatchModal from "../../components/modal/app/topMatchModal";
import UploadDocument from "../../components/modal/app/uploadDocument";
import { VscHubot } from "react-icons/vsc";
import axios from "axios";
import prettyBytes from "pretty-bytes";
import toast from "react-hot-toast";
import { truncateString } from "../../utils/helper";
import { useDropzone } from "react-dropzone";
import useFiles from "../../hooks/useFiles";
import useSiderbar from "../../hooks/useSidebar";
import useTopMatch from "../../hooks/useTopMatch";
import useTopMatchModal from "../../hooks/modal/useTopMatchModal";
import useUploadDocument from "../../hooks/modal/useUploadDocumentModal";
import useUser from "../../hooks/useUser";
import { v4 as uuidv4 } from "uuid";

const Dashboard = () => {
  const { user } = useUser();
  const [showRightBar, setShowRightBar] = useState(true);
  const uploadDocument = useUploadDocument();
  // const [files, setFiles] = useState([]);
  const { files, setFiles } = useFiles();
  const [currentFiles, setCurrentFiles] = useState([]);
  const [filesOpen, setFilesOpen] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [openFiles, setOpenFiles] = useState(false);
  const [selectedPdfForView, setSelectedPdfForView] = useState("");
  const [deleting, setDeleting] = useState(false);
  const { isSidebarOpen, setIsSidebarOpen } = useSiderbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState(null);
  const [media, setMedia] = useState("");
  const [uploadingMedia, setUploadingMedia] = useState(false);
  const [startingConversation, setStartingConversation] = useState(false);
  const [topMatches, setTopMatches] = useState([]);
  const [currentConversation, setCurrentConversation] = useState(null);
  const [sendingMessage, setSendingMessage] = useState(false);
  const { setTopMatch } = useTopMatch();
  const topMatchModal = useTopMatchModal();
  const [newMessageSend, setNewMessageSend] = useState(false)
  

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if(newMessageSend)
      {
        sendMessage()
        setNewMessageSend(false)
      }
  }, [newMessageSend])
  

  const conversation = searchParams.get("conversation");

  const updateFilesArray = async (newFilesArray) => {
    try {
      // Reference to the document you want to update
      const docRef = doc(db, "conversations", conversation);

      // Fetch the current document data
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const currentData = docSnap.data();

        // Update the files array with new files
        await updateDoc(docRef, {
          files: newFilesArray, // Update the files array
        });

        console.log("Files array successfully updated!");
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error updating files array: ", error);
    }
  };

  const onFileSelect = (file, status, all) => {
    if (all) {
      setSelectedFiles([...files]);
      if (conversation) {
        updateFilesArray([...files]);
        setCurrentConversation({ ...currentConversation, files: [...files] });
      }
      setSelectedPdfForView(files[0]);
    } else {
      if (status === true) {
        setSelectedFiles([...selectedFiles, file]);
        if (conversation) {
          updateFilesArray([...selectedFiles, file]);
          setCurrentConversation({
            ...currentConversation,
            files: [...selectedFiles, file],
          });
        }
        setSelectedPdfForView(file);
      } else {
        setSelectedFiles([
          ...selectedFiles.filter((item) => item.id !== file.id),
        ]);
        if (conversation) {
          updateFilesArray([
            ...selectedFiles.filter((item) => item.id !== file.id),
          ]);
          setCurrentConversation({
            ...currentConversation,
            files: [...selectedFiles.filter((item) => item.id !== file.id)],
          });
        }
      }
    }
  };

  const checkSelected = () => {
    const selectedFileIds = selectedFiles.map((file) => file.id);

    // Update the files array based on the presence of the ID in selectedFiles
    const updatedFiles = files.map((file) => ({
      ...file,
      selected: selectedFileIds.includes(file.id),
    }));

    setCurrentFiles([...updatedFiles]);
  };

  const startNewConversation = async () => {
    try {
      if (selectedFiles.length < 1) {
        toast.error("Select files to start conversation");
        return;
      }
      setStartingConversation(true);

      const formData = {
        user_id: user?.id,
        // doc_ids: [
        //   ...selectedFiles.map((file) => {
        //     return { doc_id: file.id };
        //   }),
        // ],
        doc_ids: [{ doc_id: selectedFiles?.[0]?.id }],
      };

      const resp = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/get_top_matches`,
        formData
      );

      const top_matches = resp?.data?.top_matches;

      const data = {
        top_matches: top_matches ? top_matches : [],
        files: selectedFiles,
        user: user?.id,
        title: "Untitled",
        createdAt: serverTimestamp(),
      };
      const result = await addDoc(collection(db, "conversations"), data);
      console.log(result.id);

      searchParams.set("conversation", result.id);
      setMessages([]);
      setSearchParams(searchParams);
      toast.success("Conversation Started");
      setStartingConversation(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
      setStartingConversation(false);
    }
  };

  const getConversationData = async () => {
    try {
      let convo = await getDoc(doc(db, "conversations", conversation));
      console.log(convo?.data().files.length);
      setSelectedFiles(convo?.data().files);
      setTopMatches(convo?.data()?.top_matches);
      setCurrentConversation({ id: convo?.id, ...convo?.data() });

      const coursesQuery = query(
        collection(db, "messages"),
        where("conversation", "==", conversation),
        orderBy("createdAt", "asc")
      );

      const messagesSnapshot = await getDocs(coursesQuery);

      const messagesTemp = [];
      messagesSnapshot.forEach(async (message) => {
        messagesTemp.push(message.data());
      });

      setMessages(messagesTemp);
      if(message)
        {
          setNewMessageSend(true)
        }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  async function removeFileFromConversations(fileId) {
    try {
      const conversationsRef = collection(db, "conversations"); // Reference to Firestore collection

      // Step 1: Fetch all documents from the "conversations" collection
      const querySnapshot = await getDocs(conversationsRef);

      // Loop through each document in the collection
      const updatePromises = querySnapshot.docs.map(async (conversationDoc) => {
        const conversationData = conversationDoc.data(); // Fetch the document data

        // Step 2: Check if the file exists in the files array
        const fileExists = conversationData.files.some(
          (file) => file.id === fileId
        );

        if (fileExists) {
          // Step 3: Remove the file from the files array
          const updatedFiles = conversationData.files.filter(
            (file) => file.id !== fileId
          );

          // Step 4: Update the Firestore document with the new files array
          const conversationDocRef = doc(
            db,
            "conversations",
            conversationDoc.id
          );
          await updateDoc(conversationDocRef, { files: updatedFiles });

          console.log(
            `File with ID: ${fileId} removed from conversation: ${conversationDoc.id}`
          );
        }
      });

      // Wait for all promises to resolve
      await Promise.all(updatePromises);
      console.log("Files removed successfully.");
    } catch (error) {
      console.error("Error removing file: ", error);
    }
  }

  const deleteFile = async (file) => {
    try {
      setDeleting(true);

      try {
        await removeFileFromConversations(file.id);
      } catch (error) {
        console.log(error);
      }

      if (selectedPdfForView?.id == file.id) {
        setSelectedFiles([...selectedFiles.filter((f) => f.id !== file.id)]);
        setSelectedPdfForView(selectedFiles?.[0]);
      }
      // Step 1: Delete the file from Firebase Storage
      const fileRef = ref(storage, `files/${file.file_name}`);
      await deleteObject(fileRef);

      // Step 2: Delete the Firestore document
      const docRef = doc(db, "files", file.id); // Replace 'your-collection-name' with your actual collection name
      await deleteDoc(docRef);

      toast.success("Document deleted successfully");

      const formData = {
        user_id: user?.id,
        doc_ids: [{ doc_id: file.id }],
      };
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/delete_embeddings`,
          formData
        )
        .then((response) => {
          console.log("Deletion Success:", response.data);
          setDeleting(false);

          // Handle success actions here
        })
        .catch((error) => {
          console.error("Deletion Error:", error);
          // toast.error(error?.response?.data?.message)
          setDeleting(false);

          // Handle error actions here
        });

      console.log("File and document deleted successfully.");
    } catch (error) {
      console.error("Error deleting file or document:", error);
      toast.error("Something went wrong!");
      setDeleting(false);
    }
  };

  // function contextClassification(docIds) {
  //   return Object.values(
  //     docIds.reduce((acc, current) => {
  //       const { doc_id, page_number } = current;
        
  //       // Check if this doc_id already exists in the accumulator
  //       if (!acc[doc_id]) {
  //         // If it doesn't exist, create a new entry with an array of page numbers
  //         acc[doc_id] = { doc_id, page_numbers: [page_number] };
  //       } else {
  //         // If it exists, just add the page_number to the array
  //         acc[doc_id].page_numbers.push(page_number);
  //       }
        
  //       return acc;
  //     }, {})
  //   );
  // }

  function contextClassification(docIds) {
    return Object.values(
      docIds.reduce((acc, current) => {
        const { doc_id, page_number } = current;
        
        // Skip the element if doc_id is -1
        if (doc_id === -1) {
          return acc;
        }
  
        // Check if this doc_id already exists in the accumulator
        if (!acc[doc_id]) {
          // If it doesn't exist, create a new entry with an array of page numbers
          acc[doc_id] = { title:currentConversation?.files.find((file)=>file?.id===doc_id)?.title, page_numbers: [page_number] };
        } else {
          // If it exists, just add the page_number to the array
          acc[doc_id].page_numbers.push(page_number);
        }
  
        return acc;
      }, {})
    );
  }

  const sendMessage = async () => {
    try {
      if (!message & !media) {
        return;
      }
      if (!conversation) {
        startNewConversation();
        return;
      }
      if (currentConversation.files.length < 1) {
        toast.error("No Files Selected for conversation");
        return;
      }
      let airep = "";
      let ai_context=""
      let media_url = null;
      if (media) {
        try {
          setUploadingMedia(true);
          const file_name = uuidv4();

          const mediaRef = ref(
            storage,
            `messages/${conversation}/${file_name}`
          );

          await uploadBytes(mediaRef, media);
          media_url = await getDownloadURL(mediaRef);

          const formData = {
            id: user?.id,
            url: media_url,
          };

          try {
            const response = await axios.post(
              `${process.env.REACT_APP_BACKEND_URL}/process_image`,
              formData
            );
            console.log("Image Success:", response.data.description);

            airep = response.data.description;
          } catch (error) {
            console.error("Image Error:", error);
            airep = error?.response?.data?.message;
          }

          setUploadingMedia(false);
        } catch (error) {
          console.log(error);
          setUploadingMedia(false);
          toast.error("Something went wrong");
          return;
        }
      }

      let data = {
        from: "user",
        text: message,
        media: media_url,
        createdAt: serverTimestamp(),
        conversation: conversation,
      };

      data = {
        id: uuidv4(),
        ...data,
      };
      const messagesTemp = [...messages, { ...data }];
      setMessages([...messagesTemp]);
      setSendingMessage(true);

      const messageTemp = message;
      setMessage("");
      setMedia("");
      let result = await addDoc(collection(db, "messages"), data);

      ///Update Conversation Title//
      const conversationRef = doc(db, "conversations", conversation);

      if (messages.length < 1) {
        await updateDoc(conversationRef, {
          title: messageTemp || "Media Chat",
        });
      }

      if (!media) {
        const formData = {
          user_id: user?.id,
          doc_ids: [
            ...currentConversation?.files?.map((file) => {
              return { doc_id: file.id };
            }),
          ],
          query: messageTemp,
        };

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/query_embeddings`,
            formData
          );
          console.log("Query Success:", response.data);
          if(response?.data?.doc_ids?.length>0)
            {
              ai_context=contextClassification(response?.data?.doc_ids)
            }


          airep = response.data.response;
        } catch (error) {
          console.error("Query Error:", error);
          airep = error?.response?.data?.message;
        }
      }

      let reply = {
        from: "ai",
        text: airep,
        context:ai_context || null,
        media: null,
        createdAt: serverTimestamp(),
        conversation: conversation,
      };

      let replyResult = await addDoc(collection(db, "messages"), reply);
      reply = {
        id: replyResult.id,
        ...reply,
      };
      setSendingMessage(false);
      setMessages([...messagesTemp, { ...reply }]);
    } catch (error) {
      console.log(error);
      setSendingMessage(false);
    }
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    console.log(acceptedFiles?.[0]);
    if (acceptedFiles?.length > 0) {
      try {
        setMedia(acceptedFiles[0]);
      } catch (error) {}
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/webp": [],
      "image/heic": [],
      "image/jfif": [],
    },
    multiple: false,
    onDrop,
  });

  useEffect(() => {
    const q = query(
      collection(db, "files"),
      where("user", "==", user?.id),
      orderBy("createdAt", "desc")
    );
    const unsub = onSnapshot(q, (querySnapshot) => {
      const files_temp = [];
      querySnapshot.forEach((doc) => {
        files_temp.push({ id: doc?.id, ...doc.data() });
      });
      setFiles(files_temp);
    });
    return unsub;
  }, []);

  useEffect(() => {
    checkSelected();
  }, [files, selectedFiles]);

  useEffect(() => {
    if (conversation) {
      getConversationData();
    } else {
      setMessages([]);
    }
  }, [conversation, files]);

  return (
    <div className="w-full h-full  bg-white rounded-lg overflow-hidden flex flex-row relative ">
      <div className="flex-grow h-full  flex flex-col p-2">
        <div className="w-full  h-[5%] min-h-[32px] flex flex-row items-center justify-between  ">
          <BiMenuAltLeft
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="text-3xl cursor-pointer    block"
          />

          <div className="flex flex-row justify-center items-center gap-2">
            <h1 className=" ">AI Chat</h1>
            <FaRegEdit className="" />
          </div>
          <div className="flex flex-row items-center justify-between gap-2">
            <TbLayoutSidebar
              onClick={() => setShowRightBar(!showRightBar)}
              className={`text-2xl cursor-pointer ${
                showRightBar ? "text-slate_blue" : "text-black"
              }`}
            />
            <Link to="/profile">
              <IoPersonCircleOutline
                className={`text-3xl text-slate_gray  duration-300 cursor-pointer ${
                  showRightBar ? "lg:hidden lg:scale-0" : "block scale-100"
                }`}
              />
            </Link>
          </div>
        </div>
        <div className="flex flex-col h-[95%]  box-border ">
          {messages ? (
            <div className="flex mx-auto flex-row gap-4">
              {topMatches.map((match) => (
                <div
                  onClick={() => {
                    setTopMatch(match);
                    topMatchModal.onOpen();
                  }}
                  className="p-2 rounded-lg bg-gray-200 relative group cursor-pointer"
                >
                  {" "}
                  {match?.["NCT Number"]}{" "}
                </div>
              ))}
            </div>
          ) : null}
          <div
            ref={containerRef}
            className=" flex flex-col gap-3 h-[90%] overflow-y-scroll no-scrollbar box-border my-2"
          >
            {!messages ? (
              <div className="text-center flex items-center justify-center mt-10">
                <Oval
                  visible={true}
                  height="60"
                  width="60"
                  color="#706aee"
                  secondaryColor="#b3b5db"
                  ariaLabel="oval-loading"
                />
              </div>
            ) : messages?.length > 0 ? (
              messages.map((message) => {
                return (
                  <div
                    className={`flex  items-end ${
                      message.from === "user" ? "flex-row-reverse" : "flex-row"
                    } gap-2`}
                  >
                    <div className="min-w-10 min-h-10 bg-light_grey rounded-full flex items-center justify-center">
                      {message.from === "user" ? (
                        <IoPersonOutline className={`text-xl text-white `} />
                      ) : (
                        <VscHubot className={`text-2xl text-white `} />
                      )}
                    </div>
                    <div className="flex flex-col gap-2 w-full ">
                      {message?.media && (
                        <img
                          src={message.media}
                          className="ml-auto w-auto max-w-[300px] h-auto max-h-[300px] object-cover"
                        />
                      )}
                      <div
                        className={` ${
                          message.text ? "p-2" : "p-0"
                        } rounded-t-2xl ${
                          message.from === "user"
                            ? "rounded-bl-2xl bg-medium_slate_blue text-white ml-auto"
                            : "rounded-br-2xl bg-grey mr-auto"
                        } overflow-hidden flex flex-col max-w-[70%] `}
                      >
                        {/* <p className="font-light">{message.text}</p> */}
                        <ReactMarkdown children={message.text} />
                      </div>
                      {message?.context?.length>0 && <div className={` ${
                          message.context ? "p-2" : "p-0"
                        } rounded-t-2xl ${
                          message.from === "user"
                            ? "rounded-bl-2xl bg-medium_slate_blue text-white ml-auto"
                            : "rounded-br-2xl bg-grey mr-auto"
                        } overflow-hidden flex flex-col max-w-[70%] `}
                        >
                          
                       {message?.context?.map(context=> <div>
                        <p><span className="font-bold">File :</span> {context?.title}</p>
                        <p><span className="font-bold">Pages :</span> {context?.page_numbers.join(',')}</p>
                        </div>)}

                        </div>}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="mx-auto w-[95%] md:w-[78%] lg:w-[70%] my-auto  flex flex-col gap-3 ">
                <h1 className="text-3xl font-bold text-center tracking-wide">
                  Welcome to AI Chat!
                </h1>
                <p className="text-slate_gray text-center tracking-wider font-light">
                  I'm here to help you analyze data, generate insights, and
                  create stunning dashboards. How can I assist you today?
                </p>
              </div>
            )}

            {sendingMessage && (
              <div className={`flex  items-end  flex-row gap-2`}>
                <div className="min-w-10 min-h-10 bg-light_grey rounded-full flex items-center justify-center">
                  <VscHubot className={`text-2xl text-white `} />
                </div>
                <div
                  className={` p-2 rounded-t-2xl 
                        rounded-br-2xl bg-grey mr-auto
                         overflow-hidden flex justify-center items-center w-[70px]  flex-col max-w-[70%] `}
                >
                  <ThreeDots
                    visible={true}
                    height="35"
                    width="35"
                    color="#706aee"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              </div>
            )}
          </div>

          <div className="flex mt-auto  h-[5%] min-h-12 flex-row items-center  w-full gap-2 relative">
            <div
              className={`${
                media ? "opacity-100" : "opacity-0 "
              } right-10 duration-300 absolute rounded-xl overflow-hidden  h-[150px] w-[150px]  bottom-12`}
            >
              {uploadingMedia && (
                <div className="w-full h-full absolute bg-black bg-opacity-40 z-10 flex items-center justify-center">
                  <Oval
                    visible={true}
                    height="30"
                    width="30"
                    color="#FFFFFF"
                    secondaryColor="#d1d1d1"
                    ariaLabel="oval-loading"
                  />
                </div>
              )}
              <AiFillCloseCircle
                onClick={() => setMedia("")}
                className="absolute cursor-pointer top-1 right-1 text-xl text-slate_blue"
              />
              {media && (
                <img
                  className="w-full h-full object-cover"
                  src={URL?.createObjectURL(media)}
                />
              )}
            </div>
            {/* <div
              {...getRootProps()}
              className="w-10 h-10 cursor-pointer flex items-center justify-center rounded-full bg-grey"
            >
              <input {...getInputProps()} />
              <IoCameraOutline className="text-slate_gray text-2xl" />
            </div> */}

            <div className="flex flex-row items-center flex-grow h-10 bg-grey rounded-full overflow-hidden">
              <input
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    sendMessage();
                  }
                }}
                placeholder="Ask a question about Clinical Trial Protocol"
                className="flex-grow bg-transparent  mr-2  ml-3 outline-none"
              />
              <div
                onClick={() => sendMessage()}
                className="ml-auto min-w-10 w-10 h-10 cursor-pointer flex items-center justify-center rounded-full bg-slate_blue"
              >
                {/* {sendingMessage ? (
                  <Oval
                    visible={true}
                    height="20"
                    width="20"
                    color="#706aee"
                    secondaryColor="#ffff"
                    ariaLabel="oval-loading"
                  />
                ) : ( */}
                <IoPaperPlaneOutline className="text-white text-2xl" />
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${
          showRightBar ? "w-[320px] min-w-[320px]" : "w-0 min-w-0"
        } duration-300 bg-white   absolute lg:relative right-0   h-full overflow-y-scroll  border-l-[1px] border-l-light_grey ${
          showRightBar ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="w-full flex items-center justify-between lg:justify-end  px-2 pt-2">
          <TbLayoutSidebar
            onClick={() => setShowRightBar(!showRightBar)}
            className={`text-2xl lg:hidden duration-300 cursor-pointer ${
              showRightBar
                ? "text-slate_blue opacity-100"
                : "text-black opacity-0"
            }`}
          />
          <Link to="/profile">
            <IoPersonCircleOutline
              className={`text-3xl text-slate_gray  duration-300 cursor-pointer `}
            />
          </Link>
        </div>

        <div
          className={`flex flex-col gap-2 ${
            showRightBar ? "duration-[1500ms] opacity-100" : "opacity-0"
          }`}
        >
          <h1 className="text-lg font-light mt-4 p-2">Attached Documents</h1>
          <div
            onClick={() => uploadDocument.onOpen()}
            className={`border-2 border-slate_blue px-2 rounded-lg  justify-between items-center  m-3 mx-4  py-1 cursor-pointer  flex`}
          >
            <LuUpload className="text-slate_blue " />
            <h1 className="font-light text-sm text-slate_gray">
              Upload Documents
            </h1>
            <LuUpload className="text-slate_blue opacity-0" />
          </div>

          <div className="flex flex-row items-center justify-between px-4">
            <Button
              onClick={() => startNewConversation()}
              label={"New Conversation"}
              small={true}
              disabled={startingConversation}
            />
          </div>

          <div className="cursor-pointer  flex flex-row justify-between items-center min-h-[30px] mx-4">
            <div
              onClick={() => setFilesOpen(!filesOpen)}
              className="flex flex-row items-center gap-1"
            >
              <div className="flex flex-row items-center gap-0">
                <IoChevronForward
                  className={`text-lg text-slate_blue duration-300 ${
                    filesOpen ? "rotate-90" : "rotate-0"
                  }`}
                />
                <FiFileText className="text-lg text-slate_blue" />
              </div>
              <h1>Files</h1>
            </div>
            <h1
              onClick={() => onFileSelect("", "", true)}
              className="underline cursor-pointer text-slate_blue"
            >
              Chat with all
            </h1>
          </div>

          <div
            className={` ${
              filesOpen ? "scale-y-100 max-h-[300px]" : "scale-y-0 max-h-[0px]"
            } flex flex-col relative origin-top  gap-4 pl-2 overflow-y-scroll no-scrollbar mt-3 mx-4  ${
              filesOpen ? "opacity-100 duration-500" : "duration-300 opacity-0"
            }`}
          >
            {deleting && (
              <div className="w-full h-full absolute top-0 right-0 bg-black bg-opacity-10 flex items-center justify-center">
                <Oval
                  visible={true}
                  height="30"
                  width="30"
                  color="#706aee"
                  secondaryColor="#b3b5db"
                  ariaLabel="oval-loading"
                />
              </div>
            )}
            {currentFiles.map((file) => (
              <div className="cursor-pointer flex flex-row justify-between items-center  ">
                <div className="flex flex-row flex-grow  items-center">
                  <div className="flex flex-row items-center gap-0">
                    <IoChevronForward
                      className={`text-sm text-slate_blue duration-300 `}
                    />
                    <input
                      className="accent-slate_blue"
                      type="checkbox"
                      checked={file?.selected}
                      onChange={(e) =>
                        onFileSelect(file, e.target.checked, false)
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-1 flex-grow ml-2">
                    <div className="flex flex-row gap-1 items-center  ">
                      <FiFileText className=" text-slate_blue" />
                      <h1 className="font-light text-sm">
                        {truncateString(file.title, 35)}
                      </h1>
                    </div>

                    <div className="flex flex-row gap-3 items-center ml-1">
                      <h1 className="text-sm text-slate_gray font-light">
                        PDF <span className="font-bold">&#x2022;</span>
                      </h1>
                      <h1 className="text-sm text-slate_gray font-light">
                        {prettyBytes(file.size)}
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => deleteFile(file)}
                  className="min-w-[30px] cursor-pointer"
                >
                  {
                    <RiDeleteBin6Line className="text-slate_blue ml-auto text-xl" />
                  }
                </div>
              </div>
            ))}
          </div>

          {/* ////Pdf reader */}
          {selectedFiles.length > 0 && (
            <div className="flex flex-col mt-3 ">
              <div className="w-full  relative">
                <div
                  onClick={() => setOpenFiles(!openFiles)}
                  className="bg-grey flex flex-row items-center py-2 cursor-pointer"
                >
                  <h1 className="font-light flex-grow ml-2">
                    {selectedPdfForView
                      ? truncateString(selectedPdfForView.title, 35)
                      : "Select Pdf"}
                  </h1>
                  <div className="min-w-4 flex justify-end mr-2">
                    <IoChevronUp
                      className={`text-slate_gray text-xl duration-300 ${
                        openFiles ? "-rotate-180" : "rotate-0"
                      }`}
                    />
                  </div>
                </div>

                <div
                  className={`absolute w-full top-10 pb-1 bg-grey overflow-hidden duration-300 px-2 origin-top ${
                    openFiles ? "scale-y-100 " : "scale-y-0 "
                  } max-h-[200px]`}
                >
                  <div className="flex flex-col gap-1 ">
                    {selectedFiles.map((file) => (
                      <div
                        onClick={() => {
                          setSelectedPdfForView(file);
                          setOpenFiles(false);
                        }}
                        className="cursor-pointer p-1 font-light hover:bg-light_grey rounded-lg"
                      >
                        <h1>{truncateString(file.title, 35)}</h1>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* //Pdf viewer */}
              {selectedPdfForView && (
                <div className="w-full h-3 ">
                  <iframe
                    src={selectedPdfForView.file_url + "#toolbar=0&navpanes=0"}
                    width="100%"
                    height="500px"
                    type="application/pdf"
                    style={{ border: "none", backgroundColor: "white" }}
                    title="PDF Viewer"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <UploadDocument />
      <TopMatchModal />
    </div>
  );
};

export default Dashboard;
