import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Landing from "../screens/auth/landing";
import Login from "../screens/auth/login";
import Signup from "../screens/auth/signup";
import { Toaster } from "react-hot-toast";
import { auth } from "../utils/firebase";
import { useEffect } from "react";

const NotFound = () => {
  return (
    <div className="flex h-full w-full  items-center justify-center text-3xl font-bold">
      Not Found!
    </div>
  );
};

export function AuthNavigation() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    
      if (pathname !== "/login" && pathname !== "/login" && pathname !== "/") {
        navigate("/login");
      }
    
  }, []);

  return (
    <div className="h-full">
      <Toaster />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />

        <Route path="*" element={<Login />} />
      </Routes>
    </div>
  );
}
