"use client";

import React, { useCallback, useEffect, useState } from "react";

import Button from "../Button";
import { IoMdClose } from "react-icons/io";

function Modal({
  isOpen,
  onClose,
  onSubmit,
  title,
  body,
  footer,
  actionLabel,
  disabled,
  secondaryAction,
  secondaryActionLabel,
}) {
  const [showModal, setShowModal] = useState(isOpen);

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);

  const handleClose = useCallback(() => {
    if (disabled) {
      return;
    }
    setShowModal(false);
    setTimeout(() => {
      onClose();
    }, 300);
  }, [disabled, onClose]);

  const handleSubmit = useCallback(() => {
    if (disabled) {
      return;
    }
    onSubmit();
  }, [disabled, onSubmit]);

  const handleSecondaryAction = useCallback(() => {
    if (disabled || !secondaryAction) {
      return;
    }
    secondaryAction();
  }, [disabled, secondaryAction]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className="
  justify-center
  items-center
  flex
  overflow-x-hidden
  overflow-y-auto
 fixed
  inset-0
 
  z-[100]
 outline-none
  focus:outline-none
  bg-neutral-800/70
 
  "
    >
      <div
        className="
        relative
        w-full
        md:w-4/6
        lg:w-3/6
        xl:w-2/5
        my-6
        mx-auto
        h-auto
        
        
       
        "
      >
        {/* CONTENT */}
        <div
          className={`
         translate
        duration-300
       
        ${showModal ? "translate-y-0" : "translate-y-full"}
        ${showModal ? "opacity-100" : "opacity-0"}

        `}
        >
          <div
            className={`
            translate
            h-full
            lg:h-auto
md:h-auto
border-0
rounded-lg
shadow-lg
relative 
flex
flex-col
w-full
bg-white
outline-none
focus:outline-none

            `}
          >
            {/* HEADER */}
            {title && (
              <div
                className="
                flex
                items-center
                justify-center
                p-6
                rounded-t
                relative
                border-b-[1px]
                "
              >
                <button
                  onClick={handleClose}
                  className="
                absolute
                p-1
                border-0
                hover-opacity-70
                transition
                left-9
                "
                >
                  <IoMdClose size={18} />
                </button>
                <div className="text-lg font-semibold">{title}</div>
              </div>
            )}
            {/* BODY */}
            <div className="p-6 relative flex-auto">{body}</div>
            {/* FOOTER */}
            {onSubmit && (
              <div className="flex flex-col gap-2 p-6">
                <div className="flex flex-row gap-4 w-full items-center ">
                  {secondaryActionLabel && secondaryAction && (
                    <Button
                      disabled={disabled}
                      onClick={handleSecondaryAction}
                      label={secondaryActionLabel}
                      outline
                    />
                  )}
                  <Button
                    disabled={disabled}
                    onClick={handleSubmit}
                    label={actionLabel}
                  />
                </div>
                {footer}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
